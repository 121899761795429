












import { Component, Prop, Vue } from 'vue-property-decorator';
import getConfiguration from '@/store/getConfiguration';
import { AttachmentApi } from '@/api';
import downloadResource from '@/helpers/downloadResource';
import * as Sentry from '@sentry/vue';

@Component({})
export default class AppAdminDownloadAttachments extends Vue {
  @Prop({ type: String, required: true })
  companyId!: string;

  isLoading = false;

  async getApi() {
    const configuration = await getConfiguration();
    return new AttachmentApi(configuration, '');
  }

  async downloadAttachments() {
    try {
      this.isLoading = true;
      const api = await this.getApi();
      const {
        data: { download_url: downloadUrl },
      } = await api.postAttachmentExport({
        company_id: this.companyId,
      });
      if (!downloadUrl) {
        throw new Error();
      }
      downloadResource(downloadUrl);
    } catch (e) {
      Sentry.captureException(e);
      if (e.response) {
        const { status } = e.response;
        if (status === 404) {
          this.$buefy.toast.open({
            message: this.$t(
              'admin.download-attachments.no-attachments',
            ) as string,
            type: 'is-warning',
          });
          return;
        }
      }
      this.$buefy.toast.open({
        message: this.$t('admin.download-attachments.unknown-error') as string,
        type: 'is-danger',
      });
    } finally {
      this.isLoading = false;
    }
  }
}

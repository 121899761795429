// eslint-disable-next-line no-shadow

import { ModelError } from '@/api';

export const FormSteps = {
  kvk: ['business_id'],
  company: [
    'address.city',
    'address.house_number',
    'address.house_number_addition',
    'address.postal_code',
    'address.street',
    'business_name',
    'creditor_type',
    'vat_number',
  ],
  contact: [
    'financial_contact.email',
    'financial_contact.name',
    'financial_contact.phone',
    'invoice_email',
    'primary_contact.email',
    'primary_contact.name',
    'primary_contact.phone',
    'primary_contact.surname',
    'primary_contact.surname_prefix',
  ],
};

export interface FormStep {
  step: string;
  field: string;
  reason: string;
}

export function parseError(
  error: ModelError,
): { field: string; reason: string } {
  const parts = error.error.toLowerCase().split('.');

  parts.shift();
  const reason = parts.pop() as string;
  const field = parts.join('.');

  return { field, reason };
}

// Returns a step and field so you can show an error on the correct field
export function getFormStep(error: ModelError): FormStep {
  if (!error.error) {
    return { step: '', field: '', reason: '' };
  }

  const { field, reason } = parseError(error);

  const step = Object.keys(FormSteps).reduce((acc: string, curStep: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (FormSteps[curStep].includes(field)) {
      return curStep;
    }
    return acc;
  }, '');

  return { step, field, reason };
}















































































import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import { Component, Vue } from 'vue-property-decorator';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { ValidationObserver } from 'vee-validate';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/vue';

@Component({
  components: { AppAuthenticationLayout, BaseFieldText, ValidationObserver },
})
export default class AppAuthenticationResetPassword extends Vue {
  resetEmail = '';

  code = '';

  newPassword = '';

  step = 0;

  stepOneLoader = false;

  stepTwoLoader = false;

  async resetPassword() {
    this.stepOneLoader = true;
    try {
      await Auth.forgotPassword(this.resetEmail);
      this.step = 1;
    } catch (e) {
      this.handleError(e, 'resetEmail');
    }
    this.stepOneLoader = false;
  }

  async confirmNewPassword() {
    this.stepTwoLoader = true;
    try {
      await Auth.forgotPasswordSubmit(
        this.resetEmail,
        this.code,
        this.newPassword,
      );
      // Auto-signin is not available, so sign out instead
      this.$buefy.toast.open({
        message: this.$t('change-password-form.success') as string,
        type: 'is-success',
        duration: 5000,
      });
      await Auth.signOut();
    } catch (e) {
      this.handleError(e, 'code');
    }
    this.stepTwoLoader = false;
  }

  handleError(e: any, field: string) {
    Sentry.captureException(e);
    const error: { [key: string]: string } = {};
    error[field] = this.$te(`validation.${e.name}`)
      ? (this.$t(`validation.${e.name}`) as string)
      : (this.$t('validation.unknown') as string);

    this.setFormErrors(error);
  }

  setFormErrors(error: { [key: string]: string }) {
    (this.$refs.form as any).setErrors(error);
  }
}

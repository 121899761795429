





















import { Component, Vue } from 'vue-property-decorator';
import TheNavigation from '@/layouts/navigation/TheNavigation.vue';

@Component({
  components: { TheNavigation },
})
export default class TheNavigationDesktop extends Vue {
  active = false;

  toggleActive() {
    this.active = !this.active;
  }
}

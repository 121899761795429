import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from '@aws-amplify/auth';
import AuthGroup from '@/logic/auth/AuthGroup';
import getConfiguration from '@/store/getConfiguration';
import { UserApi } from '@/api';

interface SignInInformation {
  email: string;
  password: string;
}

const initialState = () => ({
  user: (null as unknown) as CognitoUserInterface,
  challengedUser: (null as unknown) as CognitoUserInterface,
  challenge: (null as unknown) as string,
  signInInformation: {
    email: '',
    password: '',
  },
});
export type AuthState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: AuthState) {
      Object.assign(state, initialState());
    },

    setUser(state: AuthState, user: CognitoUserInterface) {
      state.user = user;
    },

    setChallengedUser(state: AuthState, user: CognitoUserInterface) {
      state.challengedUser = user;
    },

    setSignInInformation(state: AuthState, user: SignInInformation) {
      state.signInInformation = user;
    },
  },
  actions: {
    async getUser({ commit }: { commit: Function }, noCache = false) {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: noCache,
        });
        commit('setUser', user);
      } catch (e) {
        commit('setUser', null);
      }
    },

    async confirmSignUp(
      { commit, state }: { commit: Function; state: AuthState },
      user: { email: string; code: string },
    ) {
      try {
        await Auth.confirmSignUp(user.email, user.code);
        // Sign in does not work with custom MFA, in backend. The response is not
        // the login, but the MFA custom_challenge. Until auto-signin is implemented,
        // go to the login page.
        commit('setSignInInformation', { user: '', password: '' });
      } catch (e) {
        if (e.name === 'ExpiredCodeException') {
          await Auth.resendSignUp(user.email);
        }
        throw e;
      }
    },

    async validateEmail() {
      const configuration = await getConfiguration();
      const api = new UserApi(configuration, '');
      return api.verifyEmail();
    },
  },

  getters: {
    groups: (state: AuthState) => {
      if (!state.user) {
        return [];
      }
      return state.user.signInUserSession.accessToken.payload['cognito:groups'];
    },

    authGroup: (state: AuthState, getters: any): AuthGroup => {
      const { groups } = getters;
      return groups.length ? groups[0] : AuthGroup.None;
    },

    memberOf: (state: AuthState, getters: any) => (
      checkGroup: AuthGroup,
    ): boolean => {
      const { groups } = getters;
      if (!groups) {
        return checkGroup === AuthGroup.None;
      }
      if (checkGroup === AuthGroup.Any) {
        return groups.length > 0;
      }
      const checkGroups: AuthGroup[] = Array.isArray(checkGroup)
        ? checkGroup
        : [checkGroup];

      return (
        groups.filter((group: AuthGroup) => checkGroups.includes(group))
          .length > 0
      );
    },

    isAdmin: (state: AuthState, getters: any): boolean => {
      const { memberOf } = getters;
      return memberOf(AuthGroup.Admin);
    },

    signedIn: (state: AuthState): boolean => state.user !== null,

    email: (state: AuthState): string | undefined =>
      state.user?.attributes?.email,
  },
};

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthGroup from '@/logic/auth/AuthGroup';
import AppDashboard from '@/views/AppDashboard.vue';
import navigationGuard from '@/router/navigationGuard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: AppDashboard,
    meta: { requiresAuth: AuthGroup.Any },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/AppAdmin.vue'),
    meta: { requiresAuth: AuthGroup.Admin },
  },
  {
    path: '/admin/company/:type/:id',
    name: 'admin/company',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/AdminCompanyOverview.vue'
      ),
    meta: { requiresAuth: AuthGroup.Admin },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () =>
      import(
        /* webpackChunkName: "signin" */ '../views/AppAuthenticationSignIn.vue'
      ),
    meta: { requiresAuth: AuthGroup.None },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/AppAuthenticationResetPassword.vue'
      ),
    meta: { requiresAuth: AuthGroup.None },
  },
  {
    path: '/sign-in/challenge',
    name: 'AuthenticationChallenge',
    component: () =>
      import(
        /* webpackChunkName: "authentication-challenge" */ '../views/AppAuthenticationChallenge.vue'
      ),
    meta: { requiresAuth: AuthGroup.None },
  },
  {
    path: '/confirm-signup',
    name: 'ConfirmSignup',
    component: () =>
      import(
        /* webpackChunkName: "conform-signup" */ '../views/AppAuthenticationConfirmSignup.vue'
      ),
    meta: { requiresAuth: AuthGroup.None },
  },
  {
    path: '/signup/:group',
    name: 'Signup',
    component: () =>
      import(
        /* webpackChunkName: "sign-up" */ '../views/AppAuthenticationSignup.vue'
      ),
    meta: { requiresAuth: AuthGroup.None },
  },
  {
    path: '/profile',
    name: 'Profile',
    redirect: { name: 'profile-step', params: { step: 'check' } },
  },
  {
    path: '/profile/:step',
    name: 'profile-step',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '../views/AppCompanyProfile.vue'
      ),
    meta: { requiresAuth: AuthGroup.Any },
  },
  {
    path: '/communication-tools',
    name: 'communication-tools',
    component: () =>
      import(
        /* webpackChunkName: "communication-tools" */ '../views/AppCommunicationToolsOverview.vue'
      ),
    meta: { requiresAuth: AuthGroup.Any },
  },
  {
    path: '/export',
    name: 'export',
    component: () =>
      import(/* webpackChunkName: "export" */ '../views/AppExport.vue'),
    meta: { requiresAuth: AuthGroup.Any },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    }

    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
});

router.beforeResolve(navigationGuard);

export default router;

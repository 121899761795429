<template>
  <nav class="navigation">
    <a @click="toggleActive" class="navigation__toggle">
      <b-icon v-if="active" icon="menu-open" size="is-medium" />
      <b-icon v-if="!active" icon="menu-closed" />
    </a>
    <div v-if="active" class="navigation__menu">
      <TheNavigation />
    </div>
  </nav>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import TheNavigation from '@/layouts/navigation/TheNavigation.vue';

@Component({
  components: { TheNavigation },
})
export default class TheNavigationMobile extends Vue {
  active = false;

  toggleActive() {
    this.active = !this.active;
  }
}
</script>
<style lang="scss" scoped>
.navigation {
  display: none;
  margin: 0 24px;

  &__toggle {
    color: $white;
  }

  @include touch {
    display: block;
  }

  &__menu {
    position: fixed;
    top: 100px;
    left: 0;
    bottom: 0;
    padding: 23px;
    z-index: 10;
    width: 100%;
    background-color: $white;
  }
}
</style>




























import { Company, CompanyPrimaryContact } from '@/api';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AppAddDeclarationSummaryCompany extends Vue {
  get company(): Company {
    return this.$store.state.company.company;
  }

  get contact(): CompanyPrimaryContact | undefined {
    return this.company.primary_contact;
  }
}

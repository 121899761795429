
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateDeclaration as Declaration } from '@/api';

@Component({
  components: {},
})
export default class AppOverviewDeclarationCorrections extends Vue {
  @Prop(Object)
  declaration!: Declaration;
}

import store from '@/store';
import AuthGroup from '@/logic/auth/AuthGroup';
import { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import Vue from 'vue';

export default async function navigationGuard<V extends Vue = Vue>(
  to: Route,
  from: Route,
  next: NavigationGuardNext<V>,
) {
  if (
    to.matched.some(
      (record: RouteRecord) => record.meta.requiresAuth !== AuthGroup.None,
    )
  ) {
    try {
      await store.dispatch('auth/getUser');
      if (!store.getters['auth/memberOf'](to.meta?.requiresAuth)) {
        throw new Error('Unauthenticated');
      }

      if (store.getters['auth/isAdmin']) {
        // Redirect admin to admin page (unless your on the export page, since that's any)
        if (to.meta?.requiresAuth !== AuthGroup.Admin && to.name !== 'export') {
          next({ name: 'admin' });
        } else {
          next();
        }
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const company = { ...store.state.company.company };
      if (Object.keys(company).length === 0) {
        await store.dispatch('company/fetchCompany');
      }

      // Check if user should be in onboarding process
      if (store.getters['company/hasNoKvK']) {
        if (to.name !== 'profile-step') {
          next({ name: 'profile-step', params: { step: 'check' } });
          return;
        }
      }

      next();
    } catch (e) {
      // Sign in if unauthenticated
      next({
        path: '/sign-in',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
  }
  next();
}

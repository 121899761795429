






























































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import { ValidationObserver } from 'vee-validate';
import AppAddDeclarationMixin from '@/components/declaration/AppAddDeclarationMixin';
import { CreateDeclaration as Declaration, Period } from '@/api';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import BaseFieldSelectOption from '@/components/form/BaseFieldSelectOption';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import BaseFieldUpload from '@/components/form/BaseFieldUpload.vue';
import AppAddDeclarationFetchError from '@/components/declaration/AppAddDeclarationFetchError.vue';
import AppAddDeclarationSummary from '@/components/declaration/AppAddDeclarationSummary.vue';

enum ValueType {
  weight = 'weight',
  quantity = 'quantity',
}

@Component({
  components: {
    AppAddDeclarationFetchError,
    AppAddDeclarationSummary,
    BaseFieldSelect,
    BaseFieldText,
    BaseFieldUpload,
    ValidationObserver,
  },
})
export default class AppAddDeclarationCreditor extends Mixins(
  AppAddDeclarationMixin,
) {
  declaration: Declaration = {
    amount: '',
    attachments: [],
    month: 0,
    year: 0,
    weight: undefined,
    quantity: undefined,
    reference: '',
  };

  weightOrQuantity = ValueType.weight;

  get model(): Declaration {
    return this.declaration;
  }

  get period(): Period {
    return {
      month: this.declaration.month,
      year: this.declaration.year,
    };
  }

  // Check if this is a nil declaration or not
  get notNil(): boolean {
    return !!(
      this.declaration.amount && parseFloat(this.declaration.amount) > 0
    );
  }

  get uploadRules(): any {
    return {
      required: !this.isCorrection && this.notNil,
      ext: ['xlsx'],
    };
  }

  get weightOrQuantityOptions(): BaseFieldSelectOption[] {
    return [ValueType.weight, ValueType.quantity].map((option) => ({
      value: option,
      label: this.$t(
        `declaration.creditor.form.weight-or-quantity.${option}`,
      ) as string,
    }));
  }

  async fetchData() {
    try {
      this.isLoading = true;
      await this.fetchPeriods();
      this.fetchError = false;
    } catch (e) {
      Sentry.captureException(e);
      this.fetchError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchPeriods() {
    if (this.isCorrection) {
      this.setPeriod({
        month: this.correctionSource.month,
        year: this.correctionSource.year,
      });
      return;
    }
    const { declarationApi } = await this.getApi();
    const { data } = await declarationApi.getDeclarationPeriods();
    this.periods = data;
    if (this.periods.length) {
      this.setPeriod(this.periods[0]);
    }
  }

  getAmount(): string {
    return this.declaration.amount.replace('.', ',');
  }

  setAmount(amount: string) {
    this.declaration.amount = amount.replace(',', '.');
  }

  getValue(field: ValueType): string {
    const value = this.declaration[field];
    if (value === undefined || Number.isNaN(value)) {
      return '';
    }
    const converted = value / this.getValueMultiplier(field);

    return `${converted}`;
  }

  setValue(field: ValueType, value: string) {
    const cleaned = value.replace(',', '.');
    this.declaration[field] =
      value === '' ? 0 : parseFloat(cleaned) * this.getValueMultiplier(field);
  }

  getValueMultiplier(field: string): number {
    return field === ValueType.weight ? 1000 : 1;
  }

  updateAttachments(attachments: { key: string; name: string }[]) {
    this.declaration.attachments = attachments.map((a) => a.key);
  }

  async submit() {
    const { declarationApi } = await this.getApi();
    try {
      this.isSaving = true;
      const declaration = { ...this.declaration };

      // Strip weight or quantity if it's empty
      Object.values(ValueType).forEach((field) => {
        if (declaration[field] === undefined) {
          delete declaration[field];
        }
      });

      if (this.isCorrection) {
        declaration.source_id = this.correctionSource.id;
      }
      if (this.$store.getters['auth/isAdmin'] && this.$route.params.id) {
        declaration.company_id = this.$route.params.id;
      }
      await declarationApi.postDeclaration(declaration);
      this.step = 'result';
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      this.isSaving = false;
      this.$store.dispatch('overview/fetchDeclarations', this.getCompanyId());
    }
  }

  @Watch('weightOrQuantity', { immediate: true })
  resetValue() {
    Object.values(ValueType).forEach((type: ValueType) => {
      this.declaration[type] = undefined;
    });
  }
}
















































































































import { Component, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import AppDefaultLayout from '@/layouts/AppDefaultLayout.vue';
import { CompanyApi, CompanyOverview, CompanyType } from '@/api';
import AdminActions from '@/components/AdminActions.vue';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import BaseFieldSelectOption from '@/components/form/BaseFieldSelectOption';
import getConfiguration from '@/store/getConfiguration';
import AppAdminAddAdminForm from '@/components/AppAdminAddAdminForm.vue';
import AppAdminDownloadAttachments from '@/components/AppAdminDownloadAttachments.vue';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    AdminActions,
    AppAdminDownloadAttachments,
    BaseFieldSelect,
    AppDefaultLayout,
  },
})
export default class AppAdmin extends Vue {
  filter = {
    hasSynergyNumber: null,
    companyType: null,
  } as {
    hasSynergyNumber: boolean | null;
    companyType: CompanyType | null;
  };

  companies: CompanyOverview[] = [];

  error = false;

  isLoading = false;

  get currentPage() {
    return this.$store.state.admin.currentPage;
  }

  set currentPage(newVal: number) {
    this.$store.commit('admin/setCurrentPage', newVal);
  }

  get filteredData(): any[] {
    let filtered = this.companies;
    if (this.filter.hasSynergyNumber !== null) {
      filtered = filtered.filter(
        (item) => !!item.synergy_number === this.filter.hasSynergyNumber,
      );
    }
    if (this.filter.companyType !== null) {
      filtered = filtered.filter(
        (item) => item.company_type === this.filter.companyType,
      );
    }
    return filtered;
  }

  get synergyFilterOptions(): BaseFieldSelectOption[] {
    return [
      this.allOption,
      {
        value: true,
        label: this.$t(`admin.filter.has-synergy-number.options.yes`) as string,
      },
      {
        value: false,
        label: this.$t(`admin.filter.has-synergy-number.options.no`) as string,
      },
    ];
  }

  get companyTypeOptions(): BaseFieldSelectOption[] {
    const types = Object.values(CompanyType).map((value) => ({
      value,
      label: this.$t(`admin.filter.company-type.options.${value}`) as string,
    }));
    return [this.allOption, ...types];
  }

  get allOption(): BaseFieldSelectOption {
    return { value: null, label: this.$t('admin.filter.all') as string };
  }

  created() {
    this.fetchCompanies();
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new CompanyApi(configuration, '');
  }

  async fetchCompanies() {
    try {
      this.isLoading = true;
      this.error = false;
      const api = await this.getApi();
      const { data } = await api.getCompanyOverview();
      this.companies = data;
    } catch (e) {
      Sentry.captureException(e);
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  }

  showForm(component: any, company?: CompanyOverview) {
    this.$buefy.modal.open({
      parent: this,
      component,
      props: { company },
      hasModalCard: true,
      trapFocus: true,
    });
  }

  showAddAdminForm() {
    this.showForm(AppAdminAddAdminForm);
  }

  formattedDate(date: string) {
    return format(new Date(date), 'dd MMMM yyyy', {
      locale: nl,
    });
  }
}

import { electronicFormatIBAN } from 'ibantools';
import {
  Company,
  CompanyNotificationFrequencyEnum,
  CompanyType,
  BusinessIdCheckKvkInfo,
  ModelError,
} from '@/api/model';
import getConfiguration from '@/store/getConfiguration';
import { CompanyApi } from '@/api';
import { getFormStep } from '@/logic/profile/FormSteps';
import i18n from '@/i18n';

const initialState = () => ({
  company: {} as Company, // company of logged in user
  error: {} as ModelError,
});
export type CompanyState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: CompanyState) {
      Object.assign(state, initialState());
    },

    setCompany(state: CompanyState, company: Company) {
      state.company = company;
    },

    setCompanyIban(state: CompanyState, iban: string) {
      state.company.iban = iban;
    },

    setError(state: CompanyState, error: ModelError) {
      state.error = error;
    },
  },

  actions: {
    async fetchCompany({ commit }: { commit: Function }) {
      const configuration = await getConfiguration();
      const api = new CompanyApi(configuration, '');

      try {
        const { data } = await api.getCompany();
        commit('setCompany', data);
      } catch (e) {
        // Could not retrieve company, create empty model
        const newCompany: Company = {
          business_id: '',
          business_id_country: 'NL',
          address: {
            city: '',
            house_number: '',
            house_number_addition: null,
            postal_code: '',
            street: '',
            country: '',
          },
          vat_number: '',
          iban: '',
          financial_contact: { email: '', name: '', phone: '' },
          primary_contact: {
            email: '',
            job_title: '',
            name: '',
            phone: '',
            surname: '',
            surname_prefix: null,
          },
          notification_frequency: CompanyNotificationFrequencyEnum.Quarterly,
        };
        commit('setCompany', newCompany);
      }
    },

    async saveCompany(
      { commit, rootGetters }: { commit: Function; rootGetters: any },
      company: Company,
    ) {
      const configuration = await getConfiguration();
      const api = new CompanyApi(configuration, '');

      const isNew = company.id === undefined;
      let newCompany;
      if (isNew) {
        // Set the company_type
        const groups = rootGetters['auth/groups'];
        const companyType = groups.length ? groups[0] : CompanyType.Debtor;
        newCompany = {
          ...company,
          company_type: companyType,
        };
      } else {
        newCompany = { ...company };
      }

      // Format the IBAN
      if (newCompany.iban) {
        newCompany.iban = electronicFormatIBAN(newCompany.iban) as string;
      }

      const apiMethod = isNew ? 'postCompany' : 'putCompany';
      const { data } = await api[apiMethod](newCompany);
      commit('setCompany', data);
      commit('setError', {});
    },

    async setError(
      { commit }: { commit: Function },
      error: { message: string; error: string },
    ) {
      commit('setError', error);
    },

    prefillKvkData(
      { commit, state }: { commit: Function; state: CompanyState },
      kvkInfo: BusinessIdCheckKvkInfo,
    ) {
      if (!kvkInfo) {
        return;
      }
      const { company } = state;

      company.business_name = kvkInfo.business_name;
      if (kvkInfo.address && company.address) {
        company.address.city = kvkInfo.address.city;
        company.address.street = kvkInfo.address.street;
        company.address.house_number = kvkInfo.address.house_number;
        company.address.house_number_addition =
          kvkInfo.address.house_number_addition;
        company.address.postal_code = kvkInfo.address.postal_code;
        company.address.country = 'NL';
      }

      commit('setCompany', company);
    },
  },

  getters: {
    hasId: (state: CompanyState) => state.company.id !== undefined,

    hasSynergy: (state: CompanyState) =>
      state.company.synergy_number !== undefined &&
      state.company.synergy_number !== null,

    hasNoKvK: (state: CompanyState) =>
      state.company.business_id === undefined ||
      state.company.business_id === '',

    companyType: (state: CompanyState): CompanyType | undefined =>
      state.company.company_type,

    errors: (state: CompanyState) => (requestStep: string) => {
      const { step, field, reason } = getFormStep(state.error);
      if (step === requestStep) {
        const re = {} as {
          [key: string]: string[];
        };
        re[field] = [i18n.t(`validation.${reason}`) as string];

        return re;
      }
      return {};
    },

    primaryContactFullName: (state: CompanyState): string => {
      const contact = state.company.primary_contact;
      if (!contact) {
        return '';
      }
      const prefix = contact.surname_prefix ? `${contact.surname_prefix} ` : '';
      return `${contact.name} ${prefix}${contact.surname}`;
    },

    primaryFirstName: (state: CompanyState): string => {
      const contact = state.company.primary_contact;
      return contact ? contact.name : '';
    },

    notificationFrequency: (state: CompanyState): string => {
      const frequency = state.company.notification_frequency;
      return frequency || '';
    },
  },
};

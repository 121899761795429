/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateAttachmentExport } from '../model';
// @ts-ignore
import { CreateExport } from '../model';
// @ts-ignore
import { Export } from '../model';
/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new attachment export
         * @summary Create attachment export
         * @param {CreateAttachmentExport} [createAttachmentExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentExport: async (createAttachmentExport?: CreateAttachmentExport, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/attachment/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAttachmentExport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new export
         * @summary Create export
         * @param {CreateExport} [createExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExport: async (createExport?: CreateExport, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new attachment export
         * @summary Create attachment export
         * @param {CreateAttachmentExport} [createAttachmentExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttachmentExport(createAttachmentExport?: CreateAttachmentExport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Export>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttachmentExport(createAttachmentExport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new export
         * @summary Create export
         * @param {CreateExport} [createExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExport(createExport?: CreateExport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Export>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExport(createExport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * Creates a new attachment export
         * @summary Create attachment export
         * @param {CreateAttachmentExport} [createAttachmentExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentExport(createAttachmentExport?: CreateAttachmentExport, options?: any): AxiosPromise<Export> {
            return localVarFp.postAttachmentExport(createAttachmentExport, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new export
         * @summary Create export
         * @param {CreateExport} [createExport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExport(createExport?: CreateExport, options?: any): AxiosPromise<Export> {
            return localVarFp.postExport(createExport, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * Creates a new attachment export
     * @summary Create attachment export
     * @param {CreateAttachmentExport} [createAttachmentExport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public postAttachmentExport(createAttachmentExport?: CreateAttachmentExport, options?: any) {
        return ExportApiFp(this.configuration).postAttachmentExport(createAttachmentExport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new export
     * @summary Create export
     * @param {CreateExport} [createExport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public postExport(createExport?: CreateExport, options?: any) {
        return ExportApiFp(this.configuration).postExport(createExport, options).then((request) => request(this.axios, this.basePath));
    }
}

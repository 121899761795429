<template>
  <AppAuthenticationLayout>
    <p class="authentication__title-top">
      {{ $t('authentication.sign-in.title-top') }}
    </p>
    <h1 class="title">
      {{ $t('authentication.sign-in.title') }}
    </h1>
    <form class="form form__sign-in" @submit.prevent="signIn()">
      <b-field
        :label="$t('authentication.sign-in.form.label.email')"
        :type="emailErrorMessage.type"
        :message="emailErrorMessage.message"
      >
        <b-input
          v-model.trim="login.email"
          :placeholder="$t('authentication.sign-in.form.placeholder.email')"
          icon-pack="mrn"
        />
      </b-field>

      <b-field
        :label="$t('authentication.sign-in.form.label.password')"
        :type="passwordErrorMessage.type"
        :message="passwordErrorMessage.message"
      >
        <b-input
          v-model.trim="login.password"
          type="password"
          :placeholder="$t('authentication.sign-in.form.placeholder.password')"
          password-reveal
          icon-pack="mrn"
        />
      </b-field>

      <div class="align-right">
        <router-link
          class="authentication__reset-password"
          to="/reset-password"
          >{{ $t('authentication.sign-in.reset-password') }}</router-link
        >
      </div>
      <div class="align-right">
        <b-button native-type="submit" type="is-dark" :loading="isLoading">{{
          $t('authentication.sign-in.form.label.login')
        }}</b-button>
      </div>
    </form>
    <p class="authentication__subtitle-top">
      {{ $t('authentication.sign-in.subtitle-top') }}
    </p>
    <h2 class="title title--subtitle">
      {{ $t('authentication.sign-in.subtitle') }}
    </h2>
    <AppAuthenticationButton
      :title="$t('authentication.sign-in.register.debtor')"
      link="/signup/debtor"
    />
    <AppAuthenticationButton
      :title="$t('authentication.sign-in.register.creditor')"
      link="/signup/creditor"
    />
  </AppAuthenticationLayout>
</template>
<script>
import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Auth } from '@aws-amplify/auth';
import AppAuthenticationButton from '@/components/AppAuthenticationButton.vue';

@Component({
  components: {
    AppAuthenticationButton,
    AppAuthenticationLayout,
  },
})
export default class AppAuthenticationSignIn extends Vue {
  login = {
    email: '',
    password: '',
  };

  emailErrorMessage = {
    type: '',
    message: '',
  };

  passwordErrorMessage = {
    type: '',
    message: '',
  };

  isLoading = false;

  async signIn() {
    this.emailErrorMessage.type = '';
    this.emailErrorMessage.message = '';
    this.passwordErrorMessage.type = '';
    this.emailErrorMessage.message = '';
    try {
      this.isLoading = true;
      const user = await Auth.signIn(this.login.email, this.login.password);
      if (user.challengeName) {
        await this.$store.commit('auth/setChallengedUser', user);
        await this.$router
          .push({ name: 'AuthenticationChallenge' })
          .catch(() => {});
      }
    } catch (e) {
      switch (e.name) {
        case 'UserNotFoundException':
          this.emailErrorMessage.type = 'is-danger';
          this.emailErrorMessage.message = this.$t(
            'authentication.sign-in.form.error.email',
          );
          break;
        case 'NotAuthorizedException':
          this.passwordErrorMessage.type = 'is-danger';
          this.passwordErrorMessage.message = this.$t(
            'authentication.sign-in.form.error.password',
          );
          break;
        case 'UserNotConfirmedException':
          // Store username and password to login after verification step.
          this.$store.commit('auth/setSignInInformation', {
            email: this.login.email,
            password: this.login.password,
          });
          await this.$router.push('/confirm-signup').catch(() => {});
          break;
        default:
          this.emailErrorMessage.type = 'is-danger';
          this.emailErrorMessage.message = this.$t(
            'authentication.sign-in.form.error.other',
          );
          this.passwordErrorMessage.type = 'is-danger';
          break;
      }
    } finally {
      this.isLoading = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.form {
  margin: 33px 0;
}

.authentication__reset-password {
  color: $secondary;
  font-family: $flama-medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
  display: block;
}
</style>

const initialState = () => ({
  currentPage: 1,
});
export type AdminState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: AdminState) {
      Object.assign(state, initialState());
    },

    setCurrentPage(state: AdminState, page: number) {
      state.currentPage = page;
    },
  },
};

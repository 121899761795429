import { Component, Prop, Vue } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import {
  Company,
  CompanyNotificationFrequencyEnum,
  AttachmentApi,
  Period,
  DeclarationApi,
  NotificationApi,
} from '@/api';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import BaseFieldSelectOption from '@/components/form/BaseFieldSelectOption';
import axios from 'axios';
import getConfiguration from '@/store/getConfiguration';

@Component
export default class AppAddDeclarationMixin extends Vue {
  @Prop({ type: Object, required: false })
  correctionSource!: any;

  periods: Period[] = [];

  fetchError = false;

  step = 'form';

  isLoading = false;

  isSaving = false;

  uploads: any[] = [];

  attachments: string[] = [];

  get model(): any {
    return { month: 0, year: 0 };
  }

  get company(): Company {
    return this.$store.state.company.company;
  }

  get period(): Period {
    return {
      month: this.model.month,
      year: this.model.year,
    };
  }

  get periodOptions(): BaseFieldSelectOption[] {
    const capitalize = (s: string): string => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return this.periods.map((period) => ({
      value: period,
      label: capitalize(this.getPeriodLabel(period)),
    }));
  }

  get isCorrection(): boolean {
    return !!this.correctionSource;
  }

  created() {
    this.fetchData();
  }

  fetchData() {
    // Add implementation
  }

  add() {
    this.step = 'summary';
    this.uploadFiles();
  }

  previous() {
    this.step = 'form';
  }

  setPeriod(period: Period) {
    this.model.month = period.month || 0;
    this.model.year = period.year || 0;
  }

  getPeriodLabel(period: Period): string {
    const freq = this.company.notification_frequency;
    const pattern =
      freq === CompanyNotificationFrequencyEnum.Monthly
        ? 'MMMM yyyy'
        : 'QQQQ yyyy';

    return format(
      new Date(
        `${period.year
          .toString()
          .padStart(2, '0')}-${period.month.toString().padStart(2, '0')}-01`,
      ),
      pattern,
      {
        locale: nl,
      },
    );
  }

  async getApi() {
    const configuration = await getConfiguration();
    return {
      attachmentApi: new AttachmentApi(configuration, ''),
      declarationApi: new DeclarationApi(configuration, ''),
      notificationApi: new NotificationApi(configuration, ''),
    };
  }

  // Upload attachments
  async uploadFiles() {
    if (!this.uploads) {
      return;
    }
    try {
      this.isSaving = true;
      const uploads = this.uploads.map(async (upload) => {
        // Get the s3 url
        const url = await this.getUploadUrl(upload.name);

        // Post the file to S3
        const formData = new FormData();
        Object.keys(url.fields).forEach((key) => {
          formData.append(key, url.fields[key]);
        });
        formData.append('file', upload);
        await axios.post(url.url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // S3 object key is needed in declaration endpoint
        return { key: url.fields.key, name: upload.name };
      });

      const attachments = await Promise.all(uploads);
      this.updateAttachments(attachments);
      this.attachments = attachments.map((a) => a.name);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      this.isSaving = false;
    }
  }

  getCompanyId(): string | undefined {
    if (this.$store.getters['auth/isAdmin'] && this.$route.params.id) {
      return this.$route.params.id;
    }

    return undefined;
  }

  // empty function, should be run in extended classes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateAttachments(attachments: { key: string; name: string }[]) {}

  async getUploadUrl(name: string): Promise<any> {
    const { attachmentApi } = await this.getApi();
    const { data } = await attachmentApi.postAttachmentCreate({
      filename: name,
      company_id: this.getCompanyId(),
    });
    return data;
  }
}

<template>
  <AppAuthenticationLayout>
    <section slot="header">
      <AppOnboardingStepper :active-step="$route.params.step" />
    </section>
    <section>
      <AppProfileStepCheck v-if="$route.params.step === 'check'" />
      <AppProfileStepCompany v-else-if="$route.params.step === 'company'" />
      <AppProfileStepContact v-else-if="$route.params.step === 'contact'" />
      <div v-else>Unknown step</div>
    </section>
  </AppAuthenticationLayout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import AppProfileStepCompany from '@/components/AppProfileStepCompany.vue';
import AppProfileStepContact from '@/components/AppProfileStepContact.vue';
import AppProfileStepCheck from '@/components/AppProfileStepCheck.vue';
import AppOnboardingStepper from '@/components/AppOnboardingStepper.vue';

@Component({
  components: {
    AppOnboardingStepper,
    AppProfileStepCompany,
    AppProfileStepContact,
    AppProfileStepCheck,
    AppAuthenticationLayout,
  },
})
export default class AppCompanyProfile extends Vue {}
</script>





























































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { CompanyType } from '@/api';
import AppDefaultLayout from '@/layouts/AppDefaultLayout.vue';
import AppAddDeclarationDebtor from '@/components/declaration/AppAddDeclarationDebtor.vue';
import AppAddDeclarationCreditor from '@/components/declaration/AppAddDeclarationCreditor.vue';
import AppOverviewNotification from '@/components/overview/AppOverviewNotification.vue';
import AppOverviewDeclarations from '@/components/overview/AppOverviewDeclarations.vue';

@Component({
  components: {
    AppAddDeclarationCreditor,
    AppAddDeclarationDebtor,
    AppDefaultLayout,
    AppOverviewNotification,
    AppOverviewDeclarations,
  },
  computed: { ...mapGetters('company', ['hasSynergy', 'companyType']) },
})
export default class AppDashboard extends Vue {
  isAddDeclarationActive = false;

  created() {
    // Company not yet available, for example after refresh
    if (!this.$store.getters['company/hasId']) {
      this.$store.dispatch('company/fetchCompany');
    }
  }

  get addDeclarationComponent() {
    switch (this.$store.state.company.company.company_type) {
      case CompanyType.Creditor:
        return AppAddDeclarationCreditor;
      case CompanyType.Debtor:
        return AppAddDeclarationDebtor;
      default:
        throw new Error('Invalid company type');
    }
  }

  startDeclaration() {
    this.toggleAddDeclaration(true);
  }

  toggleAddDeclaration(active: boolean) {
    this.isAddDeclarationActive = active;
  }
}

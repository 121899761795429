




























































import { ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseFieldSelectOption from '@/components/form/BaseFieldSelectOption';

@Component({
  components: { ValidationProvider },
})
export default class BaseFieldSelect extends Vue {
  @Prop({ type: String, default: 'select' })
  type!: string;

  @Prop({ type: [String, Object, Boolean], default: '' })
  value!: any;

  @Prop({ type: Array, default: () => [] })
  options!: BaseFieldSelectOption[];

  get rules(): any {
    return this.$attrs.rules;
  }

  classNames(errors: any[]): any {
    return {
      'field--radio': this.type === 'radio',
      'field--select': this.type === 'select',
      'field--has-error': errors.length,
      'field--required': this.$attrs.rules && this.hasRule('required'),
    };
  }

  onInput(value: string) {
    this.$emit('input', value);
  }

  hasRule(rule: string): boolean {
    if (!this.rules) {
      return false;
    }
    if (typeof this.rules === 'object') {
      return !!this.rules[rule];
    }
    return this.rules.includes(rule);
  }
}

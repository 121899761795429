





































































































































import { Component } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { Company, ModelError } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import { getFormStep } from '@/logic/profile/FormSteps';
import AppProfileMixin from '@/mixins/AppProfileMixin';

@Component({
  components: {
    BaseFieldText,
    BaseFieldSelect,
    ValidationObserver,
  },
})
export default class AppProfileStepContact extends AppProfileMixin {
  stepError = 'contact';

  saving = false;

  get company(): Company {
    return this.$store.state.company.company;
  }

  get companyType(): string | undefined {
    return this.company.company_type;
  }

  async nextStep() {
    try {
      this.saving = true;
      const editing = this.$store.getters['company/hasId'];
      await this.$store.dispatch('company/saveCompany', this.company);
      if (editing) {
        this.goToDashBoard();
      } else {
        this.$buefy.dialog.alert({
          type: 'is-dark',
          title: this.$t('profile.contact.success-dialog.title') as string,
          message: this.$t(
            `profile.contact.success-dialog.${this.companyType}.text`,
          ) as string,
          confirmText: this.$t(
            'profile.contact.success-dialog.confirm',
          ) as string,
          onConfirm: this.goToDashBoard,
        });
      }
    } catch ({ response: { data } }) {
      this.handleError(data);
    } finally {
      this.saving = false;
    }
  }

  goToDashBoard() {
    this.$router.push({ name: 'Dashboard' }).catch(() => {});
  }

  handleError(error: ModelError) {
    const { step } = getFormStep(error);

    this.$store.dispatch('company/setError', error);

    this.$router
      .push({ name: 'profile-step', params: { step } })
      .catch(() => {});
  }
}



































import { Component, Vue } from 'vue-property-decorator';
import TheNavigationDesktop from '@/layouts/navigation/TheNavigationDesktop.vue';
import TheNavigationMobile from '@/layouts/navigation/TheNavigationMobile.vue';

@Component({
  components: {
    TheNavigationMobile,
    TheNavigationDesktop,
  },
})
export default class AppDefaultLayout extends Vue {}

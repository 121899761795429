






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppAuthenticationButton extends Vue {
  @Prop(String)
  title!: string;

  @Prop(String)
  link!: string;
}

// eslint-disable-next-line no-shadow
enum AuthGroup {
  None = 'none',
  Creditor = 'creditor',
  Debtor = 'debtor',
  Admin = 'admin',
  Any = 'any',
}

export default AuthGroup;

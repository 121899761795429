
























































import { Component, Vue } from 'vue-property-decorator';
import getConfiguration from '@/store/getConfiguration';
import { Company, CompanyApi, BusinessIdCheckKvkInfo } from '@/api';
import * as Sentry from '@sentry/vue';

@Component({
  components: {},
})
export default class AppProfileStepCheck extends Vue {
  businessIdValid = false;

  businessIdDisabled = false;

  businessIdErrorMessage = {
    type: '',
    message: '',
  };

  minimumLengthBusinessId = 6;

  isLoading = false;

  businessIdInfo = (null as unknown) as BusinessIdCheckKvkInfo;

  countryList: { iso: string; country: string }[] = [];

  get company(): Company {
    return this.$store.state.company.company;
  }

  created() {
    this.countryList = this.getCountryList();
    if (
      this.company.business_id &&
      this.company.business_id.length >= this.minimumLengthBusinessId
    ) {
      this.businessIdDisabled = true;
      this.businessIdValid = true;
    } else {
      this.$buefy.dialog.alert({
        type: 'is-dark',
        title: this.$t(
          'profile.contact.additional-settings-dialog.title',
        ) as string,
        message: this.$t(
          'profile.contact.additional-settings-dialog.text',
        ) as string,
        confirmText: this.$t(
          'profile.contact.additional-settings-dialog.confirm',
        ) as string,
      });
    }
  }

  async checkKvKNumber() {
    if (!this.company.business_id || this.company.business_id === '') {
      return;
    }

    const id = this.company.business_id.replace(/\s/g, '');
    if (id.length >= this.minimumLengthBusinessId) {
      try {
        this.isLoading = true;
        const {
          data: { exists, kvk_info: kvkInfo },
        } = await this.checkKvK(id);

        this.$store.dispatch('company/prefillKvkData', kvkInfo);

        if (kvkInfo) {
          this.businessIdInfo = kvkInfo;
        }

        if (exists) {
          this.manageResponse(
            false,
            `profile.business.messages.${this.kvkOrBusinessId()}.exists`,
          );
        } else {
          this.company.business_id = id;
          this.manageResponse(
            true,
            `profile.business.messages.${this.kvkOrBusinessId()}.success`,
          );
        }
      } catch (e) {
        Sentry.captureException(e);
        this.manageResponse(
          false,
          `profile.business.messages.${this.kvkOrBusinessId()}.invalid-format`,
        );
      } finally {
        this.isLoading = false;
      }
    }
  }

  async checkKvK(kvkId: string) {
    const configuration = await getConfiguration();
    const api = new CompanyApi(configuration, '');
    return api.getCompanyCheckBusinessId(
      kvkId,
      this.company.business_id_country || 'NL',
    );
  }

  getCountryList(): { iso: string; country: string }[] {
    const countryList = (this.$t('profile.countries') as unknown) as {
      [key: string]: string;
    };

    return Object.keys(countryList)
      .map((key) => ({
        iso: key,
        country: countryList[key],
      }))
      .sort((a, b) => (a.country > b.country ? 1 : -1));
  }

  kvkOrBusinessId(): string {
    return this.company.business_id_country === 'NL' ? 'kvk' : 'business_id';
  }

  nextStep() {
    this.$store.dispatch('company/prefillKvkData', this.businessIdInfo);
    this.$router
      .push({
        name: 'profile-step',
        params: { step: 'company' },
      })
      .catch(() => {});
  }

  manageResponse(success: boolean, message: string) {
    this.businessIdErrorMessage.type = success ? 'is-success' : 'is-danger';
    this.businessIdErrorMessage.message = this.$t(message) as string;
    this.businessIdValid = success;
  }
}

import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AppProfileMixin extends Vue {
  stepErrors = '';

  @Watch('backendErrors', { immediate: true })
  backendErrorsChanged(newVal: any) {
    setTimeout(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).setErrors(newVal);
      }
    }, 0);
  }

  get backendErrors() {
    return this.$store.getters['company/errors'](this.stepErrors);
  }
}

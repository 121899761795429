











































































































































import { Component, Mixins } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import { ValidationObserver } from 'vee-validate';

import { CreateNotification as Notification, Mattress } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import AppAddDeclarationSummary from '@/components/declaration/AppAddDeclarationSummary.vue';
import AppAddDeclarationMixin from '@/components/declaration/AppAddDeclarationMixin';
import AppAddDeclarationFetchError from '@/components/declaration/AppAddDeclarationFetchError.vue';
import BaseFieldUpload from '@/components/form/BaseFieldUpload.vue';

@Component({
  components: {
    AppAddDeclarationFetchError,
    AppAddDeclarationSummary,
    BaseFieldSelect,
    BaseFieldText,
    BaseFieldUpload,
    ValidationObserver,
  },
})
export default class AppAddDeclarationDebtor extends Mixins(
  AppAddDeclarationMixin,
) {
  mattressTypes: Mattress[] = [];

  notification: Notification = {
    mattresses: [],
    reference: '',
    month: 0,
    year: 0,
  };

  get model(): Notification {
    return this.notification;
  }

  async fetchData() {
    try {
      this.isLoading = true;
      await Promise.all([this.fetchPeriods(), this.fetchMattressTypes()]);
      this.fetchError = false;
    } catch (e) {
      Sentry.captureException(e);
      this.fetchError = true;
    } finally {
      this.isLoading = false;
    }
  }

  // Check if this is a nil notification by checking if there is any mattressType with an amount
  // higher then 0
  get notNil(): boolean {
    return this.notification.mattresses.some(
      (mattress) => mattress.amount !== 0,
    );
  }

  get uploadRules(): any {
    return {
      ext: ['xlsx'],
    };
  }

  async fetchPeriods() {
    if (this.isCorrection) {
      this.setPeriod({
        month: this.correctionSource.month,
        year: this.correctionSource.year,
      });
      return;
    }
    const { notificationApi } = await this.getApi();
    const { data } = await notificationApi.getNotificationPeriods();
    this.periods = data;
    if (this.periods.length) {
      this.setPeriod(this.periods[0]);
    }
  }

  async fetchMattressTypes() {
    const { notificationApi } = await this.getApi();
    // @todo Admin user needs to add company id to this request
    const { data } = await notificationApi.getMattresses();
    this.mattressTypes = data;
    this.notification.mattresses = this.mattressTypes.map((m: any) => ({
      mattress: m.id,
      amount: 0,
    }));
  }

  setMattressQuantity(amount: string, mattressId: string) {
    const mattress = this.notification.mattresses.find(
      (m: any) => m.mattress === mattressId,
    );
    if (mattress !== undefined) {
      mattress.amount = amount ? parseInt(amount, 10) : 0;
    }
  }

  getMattressQuantity(mattressId: string): number {
    const mattress = this.notification.mattresses.find(
      (m: any) => m.mattress === mattressId,
    );
    if (mattress !== undefined) {
      return mattress.amount;
    }
    return 0;
  }

  updateAttachments(attachments: { key: string; name: string }[]) {
    this.notification.attachments = attachments.map((a) => a.key);
  }

  async submit() {
    const { notificationApi } = await this.getApi();
    try {
      this.isSaving = true;
      const notification = { ...this.notification };

      if (this.isCorrection) {
        notification.source_id = this.correctionSource.id;
      }
      if (this.$store.getters['auth/isAdmin'] && this.$route.params.id) {
        notification.company_id = this.$route.params.id;
      }
      await notificationApi.postNotification(notification);
      this.step = 'result';
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      this.isSaving = false;
      await this.$store.dispatch(
        'overview/fetchNotifications',
        this.getCompanyId(),
      );
    }
  }
}



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyOverview } from '@/api';
import AppAdminEmailForm from '@/components/AppAdminEmailForm.vue';
import AppAdminSynergyForm from '@/components/AppAdminSynergyForm.vue';
import AppAdminDownloadAttachments from '@/components/AppAdminDownloadAttachments.vue';
import ClickOutside from 'vue-click-outside';

@Component({
  components: {
    AppAdminDownloadAttachments,
  },
  directives: {
    ClickOutside,
  },
})
export default class AdminActions extends Vue {
  @Prop({ type: Object, required: true })
  company!: any;

  actions = [
    { name: 'change-email', icon: 'mail', component: AppAdminEmailForm },
    { name: 'connect-synergy', icon: 'plus', component: AppAdminSynergyForm },
  ];

  active = false;

  showForm(component: any, company?: CompanyOverview) {
    this.$buefy.modal.open({
      parent: this,
      component,
      props: { company },
      hasModalCard: true,
      trapFocus: true,
    });
  }

  toggleActions() {
    this.active = !this.active;
  }

  hideMenu() {
    this.active = false;
  }
}

import getConfiguration from '@/store/getConfiguration';
import {
  CompanyNotificationFrequencyEnum,
  Declaration,
  DeclarationApi,
  Period,
  Notification,
  NotificationApi,
  CreateNotificationMattresses as NotificationMattresses,
} from '@/api';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

interface NotificationOverviewRow {
  period: string;
  declarationDate: string;
  reference: string;
  mattress: number;
  mattresses: NotificationMattresses[];
  notificationNumber: string;
  mappedCorrections: Notification[];
  totalMattress: number;
}

interface DeclarationOverviewRow {
  period: string;
  declarationDate: string;
  amount: string;
  weight: string;
  quantity: string;
  reference: string;
  declarationNumber: string;
  mappedCorrections: Declaration[];
  totals: { [key: string]: string };
}

const initialState = () => ({
  notifications: ([] as any) as Notification[],
  declarations: ([] as any) as Declaration[],
});
export type OverviewState = ReturnType<typeof initialState>;

const getPeriodLabel = (period: Period, freq: string): string => {
  const pattern =
    freq === CompanyNotificationFrequencyEnum.Monthly
      ? 'MMMM yyyy'
      : 'QQQQ yyyy';

  return format(
    new Date(
      `${period.year
        .toString()
        .padStart(2, '0')}-${period.month.toString().padStart(2, '0')}-01`,
    ),
    pattern,
    {
      locale: nl,
    },
  );
};

const getDeclarationDate = (date: string): string =>
  format(new Date(date), 'dd MMMM yyyy', {
    locale: nl,
  });

const calculateDeclarationTotals = (
  field: string,
  item: any,
  corrections: any[],
): number => {
  const base = item[field] ? parseInt(item[field], 10) : 0;
  return corrections.reduce((acc, cur) => acc + parseInt(cur[field], 10), base);
};

const formatAmount = (amount: any) =>
  amount ? `${amount} euro`.replace('.', ',') : '-';

const formatWeight = (weight: number | undefined) =>
  weight ? (weight / 1000).toString().replace('.', ',') : '-';

const formatQuantity = (quantity: number | undefined) =>
  quantity ? quantity.toString().replace('.', ',') : '-';

const mattressReducer = (mattresses: NotificationMattresses[]) =>
  mattresses.reduce((acc, cur) => acc + cur.amount, 0);

const NotificationMapping = (item: Notification, rootGetters: any): any => ({
  ...item,
  period: getPeriodLabel(
    { month: item.month, year: item.year },
    rootGetters['company/notificationFrequency'],
  ),
  declarationDate: item.created_at ? getDeclarationDate(item.created_at) : '',
  reference: item.reference || '',
  mattress: mattressReducer(item.mattresses),
  mattresses: item.mattresses
    .filter((ma) => ma.amount !== 0)
    .sort((a, b) => (a.mattress > b.mattress ? 1 : -1)),
  notificationNumber: item.notification_number || '',
  mappedCorrections: item.corrections
    ? item.corrections.map((corrections: Notification) =>
        NotificationMapping(corrections, rootGetters),
      )
    : null,
  totalMattress: item.corrections
    ? item.corrections
        .map((correction: Notification) =>
          mattressReducer(correction.mattresses),
        )
        .reduce(
          (acc: number, cur: number) => acc + cur,
          mattressReducer(item.mattresses),
        )
    : null,
});

const DeclarationMapping = (item: Declaration, rootGetters: any): any => ({
  ...item,
  period: getPeriodLabel(
    { month: item.month, year: item.year },
    rootGetters['company/notificationFrequency'],
  ),
  declarationDate: item.created_at ? getDeclarationDate(item.created_at) : '',
  amount: formatAmount(item.amount),
  weight: formatWeight(item.weight),
  quantity: formatQuantity(item.quantity),
  reference: item.reference || '',
  declarationNumber: item.declaration_number || '',
  mappedCorrections: item.corrections
    ? item.corrections.map((corrections: Declaration) =>
        DeclarationMapping(corrections, rootGetters),
      )
    : null,
  totals: item.corrections
    ? {
        amount: formatAmount(
          calculateDeclarationTotals('amount', item, item.corrections),
        ),
        weight: formatWeight(
          calculateDeclarationTotals('weight', item, item.corrections),
        ),
        quantity: formatQuantity(
          calculateDeclarationTotals('quantity', item, item.corrections),
        ),
      }
    : null,
});

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: OverviewState) {
      Object.assign(state, initialState());
    },

    setNotifications(state: OverviewState, notifications: Notification[]) {
      state.notifications = notifications;
    },

    setDeclarations(state: OverviewState, declaration: Declaration[]) {
      state.declarations = declaration;
    },
  },

  actions: {
    async fetchNotifications(
      { commit }: { commit: Function },
      companyId?: string,
    ) {
      const configuration = await getConfiguration();
      const api = await new NotificationApi(configuration, '');
      const { data } = await api.getNotificationOverview(companyId);

      commit('setNotifications', data);
    },

    async fetchDeclarations(
      { commit }: { commit: Function },
      companyId?: string,
    ) {
      const configuration = await getConfiguration();
      const api = await new DeclarationApi(configuration, '');
      const { data } = await api.getDeclarationOverview(companyId);
      commit('setDeclarations', data);
    },
  },

  getters: {
    notifications: (
      state: OverviewState,
      getters: any,
      rootState: any,
      rootGetters: any,
    ): NotificationOverviewRow[] =>
      state.notifications
        .filter((item: Notification) => item.source_id === null)
        .sort((a, b) => {
          if (a.year === b.year) {
            return a.month > b.month ? -1 : 1;
          }
          return a.year > b.year ? -1 : 1;
        })
        .map((item) => NotificationMapping(item, rootGetters)),

    declarations: (
      state: OverviewState,
      getters: any,
      rootState: any,
      rootGetters: any,
    ): DeclarationOverviewRow[] =>
      state.declarations
        .filter((item: Declaration) => item.source_id === null)
        .sort((a, b) => {
          if (a.year === b.year) {
            return a.month > b.month ? -1 : 1;
          }
          return a.year > b.year ? -1 : 1;
        })
        .map((item) => DeclarationMapping(item, rootGetters)),
  },
};

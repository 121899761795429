













































import { ValidationProvider } from 'vee-validate';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { ValidationProvider },
})
export default class BaseFieldText extends Vue {
  @Prop({ type: [String, Number], default: '' })
  value!: string | number;

  @Prop({ type: String, default: '' })
  unit!: string;

  @Prop({ type: Boolean, default: false })
  nullable!: boolean;

  get rules(): any {
    return this.$attrs.rules;
  }

  get inputType(): string {
    if (this.hasRule('quantity')) {
      return 'number';
    }

    const validTypes = ['text', 'email', 'password'];
    if (this.$attrs.rules) {
      const type = validTypes.find((rule) => this.hasRule(rule));
      if (type) {
        return type;
      }
    }
    return 'text';
  }

  get min(): number | undefined {
    return this.hasRule('quantity') ? 0 : parseInt(this.$attrs.min, 10);
  }

  get listeners() {
    // Only take blur and focus from parent, not input
    delete this.$listeners.input;
    return this.$listeners;
  }

  classNames(errors: any[]): any {
    return {
      'field--has-error': errors.length,
      'field--required': this.hasRule('required'),
      'field--has-unit': !!this.unit,
    };
  }

  onInput(value: string | number) {
    const emit = this.nullable && !value ? null : value;
    this.$emit('input', emit);
  }

  hasRule(rule: string): boolean {
    return this.rules && this.rules.includes(rule);
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateExport
 */
export interface CreateExport {
    /**
     * First month for data export (inclusive, first month in case of quarterly notifications)
     * @type {number}
     * @memberof CreateExport
     */
    month_start: number;
    /**
     * First year for data export (inclusive)
     * @type {number}
     * @memberof CreateExport
     */
    year_start: number;
    /**
     * Last month for data export (inclusive, first month in case of quarterly notifications)
     * @type {number}
     * @memberof CreateExport
     */
    month_end: number;
    /**
     * Last year for data export (inclusive)
     * @type {number}
     * @memberof CreateExport
     */
    year_end: number;
    /**
     * The type of data that needs to be exported
     * @type {string}
     * @memberof CreateExport
     */
    type: CreateExportTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateExportTypeEnum {
    Notifications = 'notifications',
    Declarations = 'declarations'
}




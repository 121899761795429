

















import { Component, Vue } from 'vue-property-decorator';
import AppDefaultLayout from '@/layouts/AppDefaultLayout.vue';
import AppOverviewNotification from '@/components/overview/AppOverviewNotification.vue';
import AppOverviewDeclarations from '@/components/overview/AppOverviewDeclarations.vue';
import getConfiguration from '@/store/getConfiguration';
import { CompanyApi } from '@/api';
import AppCompanyDetailOverview from '@/components/AppCompanyDetailOverview.vue';

@Component({
  components: {
    AppDefaultLayout,
    AppOverviewNotification,
    AppOverviewDeclarations,
    AppCompanyDetailOverview,
  },
})
export default class AppCompanyOverview extends Vue {
  company: any = {};

  get companyId() {
    return this.$route.params.id;
  }

  get companyType() {
    return this.$route.params.type;
  }

  async created() {
    const api = await this.getApi();
    const { data } = await api.getCompany(this.companyId);
    this.company = data;
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new CompanyApi(configuration, '');
  }
}

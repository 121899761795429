/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BusinessIdCheck } from '../model';
// @ts-ignore
import { Company } from '../model';
// @ts-ignore
import { CompanyOverview } from '../model';
// @ts-ignore
import { UpdateSynergy } from '../model';
/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves company details.
         * @param {string} [id] ID of the company the fetch. Only allowed for admin users. For normal users no id param should be specified, as the correct company will be fetched automatically.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if a company with the given combination of business_id, business_id_country and company_type exists.
         * @summary Check if business_id exists
         * @param {string} businessId Unique business identifier
         * @param {string} businessIdCountry Country code in ISO 3166-1 alpha-2 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCheckBusinessId: async (businessId: string, businessIdCountry: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getCompanyCheckBusinessId', 'businessId', businessId)
            // verify required parameter 'businessIdCountry' is not null or undefined
            assertParamExists('getCompanyCheckBusinessId', 'businessIdCountry', businessIdCountry)
            const localVarPath = `/company/check-business-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (businessId !== undefined) {
                localVarQueryParameter['business_id'] = businessId;
            }

            if (businessIdCountry !== undefined) {
                localVarQueryParameter['business_id_country'] = businessIdCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the known companies and their primary user contact information.
         * @summary Get company and username information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOverview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the synergy number of a company.
         * @summary Update the synergy number of a company.
         * @param {UpdateSynergy} [updateSynergy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanySynergy: async (updateSynergy?: UpdateSynergy, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/synergy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSynergy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new company
         * @summary Creates a new company
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany: async (company?: Company, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing company.
         * @summary Updates an existing company.
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany: async (company?: Company, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves company details.
         * @param {string} [id] ID of the company the fetch. Only allowed for admin users. For normal users no id param should be specified, as the correct company will be fetched automatically.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks if a company with the given combination of business_id, business_id_country and company_type exists.
         * @summary Check if business_id exists
         * @param {string} businessId Unique business identifier
         * @param {string} businessIdCountry Country code in ISO 3166-1 alpha-2 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyCheckBusinessId(businessId: string, businessIdCountry: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessIdCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyCheckBusinessId(businessId, businessIdCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the known companies and their primary user contact information.
         * @summary Get company and username information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyOverview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyOverview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the synergy number of a company.
         * @summary Update the synergy number of a company.
         * @param {UpdateSynergy} [updateSynergy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompanySynergy(updateSynergy?: UpdateSynergy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompanySynergy(updateSynergy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new company
         * @summary Creates a new company
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompany(company?: Company, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompany(company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing company.
         * @summary Updates an existing company.
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompany(company?: Company, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompany(company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * Retrieves company details.
         * @param {string} [id] ID of the company the fetch. Only allowed for admin users. For normal users no id param should be specified, as the correct company will be fetched automatically.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(id?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if a company with the given combination of business_id, business_id_country and company_type exists.
         * @summary Check if business_id exists
         * @param {string} businessId Unique business identifier
         * @param {string} businessIdCountry Country code in ISO 3166-1 alpha-2 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCheckBusinessId(businessId: string, businessIdCountry: string, options?: any): AxiosPromise<BusinessIdCheck> {
            return localVarFp.getCompanyCheckBusinessId(businessId, businessIdCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the known companies and their primary user contact information.
         * @summary Get company and username information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyOverview(options?: any): AxiosPromise<Array<CompanyOverview>> {
            return localVarFp.getCompanyOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the synergy number of a company.
         * @summary Update the synergy number of a company.
         * @param {UpdateSynergy} [updateSynergy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanySynergy(updateSynergy?: UpdateSynergy, options?: any): AxiosPromise<CompanyOverview> {
            return localVarFp.patchCompanySynergy(updateSynergy, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new company
         * @summary Creates a new company
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany(company?: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.postCompany(company, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing company.
         * @summary Updates an existing company.
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany(company?: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.putCompany(company, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * Retrieves company details.
     * @param {string} [id] ID of the company the fetch. Only allowed for admin users. For normal users no id param should be specified, as the correct company will be fetched automatically.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompany(id?: string, options?: any) {
        return CompanyApiFp(this.configuration).getCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if a company with the given combination of business_id, business_id_country and company_type exists.
     * @summary Check if business_id exists
     * @param {string} businessId Unique business identifier
     * @param {string} businessIdCountry Country code in ISO 3166-1 alpha-2 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyCheckBusinessId(businessId: string, businessIdCountry: string, options?: any) {
        return CompanyApiFp(this.configuration).getCompanyCheckBusinessId(businessId, businessIdCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the known companies and their primary user contact information.
     * @summary Get company and username information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyOverview(options?: any) {
        return CompanyApiFp(this.configuration).getCompanyOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the synergy number of a company.
     * @summary Update the synergy number of a company.
     * @param {UpdateSynergy} [updateSynergy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public patchCompanySynergy(updateSynergy?: UpdateSynergy, options?: any) {
        return CompanyApiFp(this.configuration).patchCompanySynergy(updateSynergy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new company
     * @summary Creates a new company
     * @param {Company} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public postCompany(company?: Company, options?: any) {
        return CompanyApiFp(this.configuration).postCompany(company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing company.
     * @summary Updates an existing company.
     * @param {Company} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public putCompany(company?: Company, options?: any) {
        return CompanyApiFp(this.configuration).putCompany(company, options).then((request) => request(this.axios, this.basePath));
    }
}
























































import { Component, Vue } from 'vue-property-decorator';
import AppDefaultLayout from '@/layouts/AppDefaultLayout.vue';
import { ValidationObserver } from 'vee-validate';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import BaseFieldSelectOption from '@/components/form/BaseFieldSelectOption';
import { CreateExportTypeEnum, ExportApi, Period } from '@/api';
import { eachQuarterOfInterval, format } from 'date-fns';
import { nl } from 'date-fns/locale';
import getConfiguration from '@/store/getConfiguration';
import AuthGroup from '@/logic/auth/AuthGroup';
import downloadResource from '@/helpers/downloadResource';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    AppDefaultLayout,
    ValidationObserver,
    BaseFieldSelect,
  },
})
export default class AppExport extends Vue {
  periods: Period[] = [];

  loading = {
    declarations: false,
    notifications: false,
  };

  showError = '';

  exportPeriod = {
    startPeriod: {
      month: 0,
      year: 0,
    } as Period,
    endPeriod: {
      month: 0,
      year: 0,
    } as Period,
  };

  request = null as any;

  get group(): AuthGroup {
    return this.$store.getters['auth/authGroup'];
  }

  get downloadTypes(): CreateExportTypeEnum[] {
    const types = [];
    if (this.group === AuthGroup.Admin || this.group === AuthGroup.Creditor) {
      types.push(CreateExportTypeEnum.Declarations);
    }
    if (this.group === AuthGroup.Admin || this.group === AuthGroup.Debtor) {
      types.push(CreateExportTypeEnum.Notifications);
    }
    return types;
  }

  created() {
    this.generatePeriodList();
  }

  generatePeriodList() {
    const startYear = 2021;
    const currentYear = new Date(Date.now()).getFullYear();
    const quarters = eachQuarterOfInterval({
      start: new Date(startYear, 1, 1),
      end: new Date(currentYear, 10, 1),
    });
    this.periods = quarters.map((quarter) => ({
      month: quarter.getMonth() + 1,
      year: quarter.getFullYear(),
    }));
  }

  setStartPeriod(period: Period) {
    this.exportPeriod.startPeriod = period;
  }

  setEndPeriod(period: Period) {
    this.exportPeriod.endPeriod = period;
  }

  get periodOptions(): BaseFieldSelectOption[] {
    const capitalize = (s: string): string => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return this.periods.map((period) => ({
      value: period,
      label: capitalize(
        format(
          new Date(
            `${period.year
              .toString()
              .padStart(2, '0')}-${period.month
              .toString()
              .padStart(2, '0')}-01`,
          ),
          'QQQQ yyyy',
          {
            locale: nl,
          },
        ),
      ),
    }));
  }

  async downloadExport(type: CreateExportTypeEnum) {
    this.showError = '';
    this.setLoading(true, type);
    const request = this.buildRequest(type);

    const configuration = await getConfiguration();
    const api = new ExportApi(configuration, '');
    try {
      const {
        data: { download_url: downloadUrl },
      } = await api.postExport(request);
      if (downloadUrl) {
        downloadResource(downloadUrl);
      } else {
        throw new Error('404');
      }
    } catch (e) {
      Sentry.captureException(e);
      this.showError = e.message === '404' ? '404' : 'unknown';
    } finally {
      this.setLoading(false, type);
    }
  }

  buildRequest(type: CreateExportTypeEnum) {
    return {
      month_start: this.exportPeriod.startPeriod.month,
      year_start: this.exportPeriod.startPeriod.year,
      month_end: this.exportPeriod.endPeriod.month,
      year_end: this.exportPeriod.endPeriod.year,
      type,
    };
  }

  setLoading(state: boolean, type: CreateExportTypeEnum) {
    if (this.downloadTypes.includes(type)) {
      this.loading[type] = state;
    }
  }
}




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import getConfiguration from '@/store/getConfiguration';
import { Company, CompanyApi, CompanyOverview } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import AppCompanyDetailOverview from '@/components/AppCompanyDetailOverview.vue';
import * as Sentry from '@sentry/vue';

interface FormError {
  type?: string;
  message?: string;
}

@Component({
  components: {
    BaseFieldText,
    ValidationObserver,
    AppCompanyDetailOverview,
  },
})
export default class AppAdminSynergyForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  company!: CompanyOverview;

  companyDetails: Company = {};

  synergyNumber = '';

  sendSubmissionReminders: boolean | undefined | null = null;

  error: FormError = {};

  isSaving = false;

  created() {
    this.synergyNumber = this.company.synergy_number || '';
    this.loadExtendedCompanyInformation(this.company.id);
  }

  @Watch('synergyNumber', { immediate: true })
  resetError() {
    this.error = {};
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new CompanyApi(configuration, '');
  }

  async save() {
    try {
      this.isSaving = true;
      const api = await this.getApi();

      const company = this.companyDetails;
      company.synergy_number = this.synergyNumber;
      company.send_submission_reminders = this
        .sendSubmissionReminders as boolean;

      await api.putCompany(company);
      this.$emit('close');
    } catch (e) {
      Sentry.captureException(e);
      this.error = {
        type: 'is-danger',
        message: this.$t('admin.synergy-form.error') as string,
      };
    } finally {
      this.isSaving = false;
    }
  }

  async loadExtendedCompanyInformation(id = '') {
    const api = await this.getApi();
    const { data } = await api.getCompany(id);
    this.companyDetails = data as Company;
    this.sendSubmissionReminders = this.companyDetails.send_submission_reminders;
  }
}

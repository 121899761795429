
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Notification } from '@/api';

@Component({
  components: {},
})
export default class AppOverviewNotificationCorrections extends Vue {
  @Prop(Object)
  notification!: Notification;
}

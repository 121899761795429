/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CompanyAddress } from './company-address';
import { CompanyFinancialContact } from './company-financial-contact';
import { CompanyPrimaryContact } from './company-primary-contact';
import { CompanyType } from './company-type';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {CompanyFinancialContact}
     * @memberof Company
     */
    financial_contact?: CompanyFinancialContact;
    /**
     * Unique identifier. Required when updating an existing profile.
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * The email address that the invoice gets send to
     * @type {string}
     * @memberof Company
     */
    invoice_email?: string;
    /**
     * Company\'s unique business identifier. Not updatable after creation.
     * @type {string}
     * @memberof Company
     */
    business_id?: string;
    /**
     * Country code for the business id in ISO 3166-1 alpha-2 format.
     * @type {string}
     * @memberof Company
     */
    business_id_country?: string;
    /**
     * Frequency for notifications. Not updatable after creation.
     * @type {string}
     * @memberof Company
     */
    notification_frequency?: CompanyNotificationFrequencyEnum;
    /**
     * 
     * @type {CompanyPrimaryContact}
     * @memberof Company
     */
    primary_contact?: CompanyPrimaryContact;
    /**
     * Company\'s VAT number. Should not contain any non-alphanumerical characters.
     * @type {string}
     * @memberof Company
     */
    vat_number?: string;
    /**
     * Company\'s International Bank Account Number (IBAN)
     * @type {string}
     * @memberof Company
     */
    iban?: string;
    /**
     * 
     * @type {CompanyType}
     * @memberof Company
     */
    company_type?: CompanyType;
    /**
     * The name of the company.
     * @type {string}
     * @memberof Company
     */
    business_name?: string;
    /**
     * 
     * @type {CompanyAddress}
     * @memberof Company
     */
    address?: CompanyAddress;
    /**
     * The synergy number.
     * @type {string}
     * @memberof Company
     */
    synergy_number?: string;
    /**
     * Indicates whether reminder emails should be sent for new or overdue submissions
     * @type {boolean}
     * @memberof Company
     */
    send_submission_reminders?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyNotificationFrequencyEnum {
    Monthly = 'monthly',
    Quarterly = 'quarterly'
}










import { Component, Vue } from 'vue-property-decorator';
import { Hub } from 'aws-amplify';

@Component({})
export default class App extends Vue {
  created() {
    this.$store.dispatch('auth/getUser');
    Hub.listen('auth', this.onAuth);
    document.title = 'Matras Recycling Nederland';
  }

  // TechnicalDebt: remove any from call
  async onAuth(data: any) {
    const { payload } = data;

    if (payload.event === 'signIn') {
      await this.$store.dispatch('auth/getUser');
      // Redirect admin to admin page
      if (this.$store.getters['auth/isAdmin']) {
        await this.$router.push({ name: 'admin' }).catch(() => {});
        return;
      }
      await this.$store.dispatch('company/fetchCompany');

      const redirect: any = this.$route.query.redirect || '/';
      await this.$router.push(redirect).catch(() => {});
    }
    if (payload.event === 'signOut') {
      await this.$store.dispatch('resetState');
      await this.$router.push('/sign-in').catch(() => {});
    }
    // When 'autoSignIn' and 'autoSignIn_failure' are available, implement these
  }
}

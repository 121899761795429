import { extend, configure } from 'vee-validate';
import {
  email,
  ext,
  integer,
  length,
  // eslint-disable-next-line camelcase
  max_value,
  // eslint-disable-next-line camelcase
  min_value,
  numeric,
  regex,
  required,
} from 'vee-validate/dist/rules';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import i18n from './i18n';

configure({
  defaultMessage: (field: string, values: Record<string, any>): string => {
    // override the field name.
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    values._field_ = i18n.t(`fields.${field}`);
    // eslint-disable-next-line no-underscore-dangle
    return i18n.t(`validation.${values._rule_}`, values) as string;
  },
});

// Built-in rules
extend('email', email);
extend('ext', ext);
extend('integer', integer);
extend('max_value', (value, args: any) =>
  max_value.validate(value.replace(',', '.'), { max: args[0] }),
);
extend('required', required);

// Custom rules
extend(
  'code',
  (value: string): boolean =>
    length.validate(value, { length: 6 }) && numeric.validate(value),
);

extend('currency', (value: string): boolean =>
  regex.validate(value, { regex: /^\d*[,.]?[\d]{0,2}$/ }),
);

extend('iban', (value: string): boolean => {
  const iban = electronicFormatIBAN(value);
  if (!iban) {
    return false;
  }
  return isValidIBAN(iban);
});

extend(
  'positive',
  (value: string): boolean => parseFloat(value.replace(',', '.')) >= 0,
);

extend(
  'password',
  (value: string): boolean =>
    value.match(/(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/) !==
    null,
);

extend('password-repeat', {
  params: ['target'],
  validate(value, { target }: any): boolean {
    return value === target;
  },
});

extend(
  'quantity',
  (value) => integer.validate(value) && min_value.validate(value, { min: 0 }),
);

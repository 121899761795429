































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AddCorrectionButton from '@/components/declaration/AddCorrectionButton.vue';
import AppOverviewDeclarationCorrections from '@/components/overview/AppOverviewDeclarationCorrections.vue';
import { Declaration } from '@/api';

@Component({
  computed: { ...mapGetters('overview', ['declarations']) },
  components: {
    AddCorrectionButton,
    AppOverviewDeclarationCorrections,
  },
})
export default class AppOverviewDeclarations extends Vue {
  isLoading = false;

  @Prop({ type: String, default: undefined })
  companyId!: string | undefined;

  created() {
    this.$store.dispatch('overview/fetchDeclarations', this.companyId);
  }

  hasDetails(declarations: Declaration): boolean {
    return !!declarations.corrections && declarations.corrections.length > 0;
  }
}

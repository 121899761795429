import { Auth } from 'aws-amplify';
import { Configuration } from '@/api';

export default async function getConfiguration() {
  const configuration = new Configuration();

  try {
    const session: any = await Auth.currentSession();
    configuration.baseOptions = {
      headers: {
        authorization: session.idToken.jwtToken,
      },
    };

    return configuration;
  } catch (e) {
    return configuration;
  }
}

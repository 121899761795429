









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AddCorrectionButton from '@/components/declaration/AddCorrectionButton.vue';
import AppOverviewNotificationCorrections from '@/components/overview/AppOverviewNotificationCorrections.vue';
import { Notification } from '@/api';

@Component({
  computed: { ...mapGetters('overview', ['notifications']) },
  components: {
    AddCorrectionButton,
    AppOverviewNotificationCorrections,
  },
})
export default class AppOverviewNotification extends Vue {
  isLoading = false;

  @Prop({ type: String, default: undefined })
  companyId!: string | undefined;

  created() {
    this.$store.dispatch('overview/fetchNotifications', this.companyId);
  }

  hasDetails(notification: Notification): boolean {
    return !!notification.corrections && notification.corrections.length > 0;
  }
}





































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppOnboardingStepper extends Vue {
  @Prop(String)
  activeStep!: string;

  isActiveStep(step: string): boolean {
    return (
      (['kvk', 'company'].includes(this.activeStep) && step === 'company') ||
      (this.activeStep === 'contact' && step === 'contact')
    );
  }
}

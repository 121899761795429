import Vue from 'vue';
import Vuex from 'vuex';

import AdminStore from '@/store/AdminStore';
import AuthStore from '@/store/AuthStore';
import CompanyStore from '@/store/CompanyStore';
import OverviewStore from '@/store/OverviewStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    resetState({ commit }: { commit: Function }) {
      commit('admin/resetState');
      commit('auth/resetState');
      commit('company/resetState');
      commit('overview/resetState');
    },
  },
  modules: {
    admin: AdminStore,
    auth: AuthStore,
    company: CompanyStore,
    overview: OverviewStore,
  },
});















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import getConfiguration from '@/store/getConfiguration';
import { UserApi, UserGroup } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import AuthGroup from '@/logic/auth/AuthGroup';
import * as Sentry from '@sentry/vue';

interface FormError {
  type?: string;
  message?: string;
}

@Component({
  components: {
    BaseFieldText,
    ValidationObserver,
  },
})
export default class AppAdminAddAdminForm extends Vue {
  email = '';

  error: FormError = {};

  isSaving = false;

  @Watch('email', { immediate: true })
  resetError() {
    this.error = {};
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new UserApi(configuration, '');
  }

  async save() {
    try {
      this.isSaving = true;
      const api = await this.getApi();
      await api.inviteUser({
        email: this.email,
        group: (AuthGroup.Admin as string) as UserGroup,
      });
      this.$emit('close');
    } catch (e) {
      Sentry.captureException(e);
      this.error = {
        type: 'is-danger',
        message: this.$t('admin.add-admin-form.error') as string,
      };
    } finally {
      this.isSaving = false;
    }
  }
}







































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/vue';

interface FormError {
  type?: string;
  message?: string;
}

@Component({
  components: {
    BaseFieldText,
    ValidationObserver,
  },
})
export default class AppAdminEmailForm extends Vue {
  currentPassword = '';

  newPassword = '';

  newPasswordRepeat = '';

  error: FormError = {};

  isSaving = false;

  @Watch('currentPassword', { immediate: true })
  @Watch('newPassword', { immediate: true })
  resetError() {
    this.error = {};
  }

  async save() {
    try {
      this.isSaving = true;
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, this.currentPassword, this.newPassword);
      this.$emit('close');
      this.$buefy.toast.open({
        message: this.$t('change-password-form.success') as string,
        type: 'is-success',
        duration: 5000,
      });
      await Auth.signOut();
    } catch (e) {
      Sentry.captureException(e);
      let error: string;
      switch (e.code) {
        case 'NotAuthorizedException':
          error = 'error-password-incorrect';
          break;
        case 'LimitExceededException':
          error = 'error-limit-exceeded';
          break;
        default:
          error = 'error-unknown';
          break;
      }
      this.error = {
        type: 'is-danger',
        message: this.$t(`change-password-form.${error}`) as string,
      };
    } finally {
      this.isSaving = false;
    }
  }
}






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { electronicFormatIBAN } from 'ibantools';
import { Company } from '@/api';

@Component({})
export default class AppCompanyDetailOverview extends Vue {
  @Prop({
    type: Object,
    default: () => ({} as Company),
  })
  company!: Company;

  electronicFormatIBAN = electronicFormatIBAN;

  primaryContactFullName() {
    const contact = this.company.primary_contact;
    if (!contact) {
      return '';
    }

    const prefix = contact.surname_prefix ? `${contact.surname_prefix} ` : '';
    return `${contact.name} ${prefix}${contact.surname}`;
  }
}

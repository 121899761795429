


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { CompanyType } from '@/api';
import AppAddDeclarationDebtor from '@/components/declaration/AppAddDeclarationDebtor.vue';
import AppAddDeclarationCreditor from '@/components/declaration/AppAddDeclarationCreditor.vue';
import ClickOutside from 'vue-click-outside';

@Component({
  components: {
    AppAddDeclarationCreditor,
    AppAddDeclarationDebtor,
  },
  computed: { ...mapGetters('company', ['hasSynergy', 'companyType']) },
  directives: {
    ClickOutside,
  },
})
export default class AppDashboard extends Vue {
  isAddCorrectionActive = false;

  @Prop({ type: Object, required: true })
  source!: any;

  active = false;

  get addCorrectionComponent() {
    switch (this.companyType) {
      case CompanyType.Creditor:
        return AppAddDeclarationCreditor;
      case CompanyType.Debtor:
        return AppAddDeclarationDebtor;
      default:
        throw new Error('Invalid company type');
    }
  }

  get companyType(): string {
    return (
      this.$route.params.type || this.$store.state.company.company.company_type
    );
  }

  startCorrection() {
    this.toggleAddCorrection(true);
  }

  toggleAddCorrection(active: boolean) {
    this.isAddCorrectionActive = active;
  }

  toggleActions() {
    this.active = !this.active;
  }

  hideMenu() {
    this.active = false;
  }
}

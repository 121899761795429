
























































































import { Component, Vue } from 'vue-property-decorator';
import { Auth } from '@aws-amplify/auth';
import AppAdminEmailForm from '@/components/AppAdminEmailForm.vue';
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';

@Component
export default class TheNavigation extends Vue {
  signOut(): void {
    Auth.signOut();
  }

  externalLinks(): any[] {
    return [
      {
        link: 'https://www.mrn.nl/contact/',
        icon: 'contact',
        label: this.$t('navigation.contact'),
      },
      {
        link: 'https://www.mrn.nl/faq',
        icon: 'info',
        label: this.$t('navigation.faq'),
      },
    ];
  }

  openChangeEmail() {
    this.$buefy.modal.open({
      parent: this,
      component: AppAdminEmailForm,
      props: { mode: 'self' },
      hasModalCard: true,
      trapFocus: true,
    });
  }

  openChangePassword() {
    this.$buefy.modal.open({
      parent: this,
      component: ChangePasswordForm,
      props: { mode: 'self' },
      hasModalCard: true,
      trapFocus: true,
    });
  }
}































































import { ValidationProvider } from 'vee-validate';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { ValidationProvider },
})
export default class BaseFieldUpload extends Vue {
  @Prop({ type: Array, default: () => [] })
  value!: any[];

  get rules(): any {
    return this.$attrs.rules;
  }

  classNames(errors: any[]): any {
    return {
      'field--has-error': errors.length,
      'field--required': this.$attrs.rules && this.hasRule('required'),
    };
  }

  onInput(value: string | number) {
    this.$emit('input', value);
  }

  hasRule(rule: string): boolean {
    if (!this.rules) {
      return false;
    }
    if (typeof this.rules === 'object') {
      return !!this.rules[rule];
    }
    return this.rules.includes(rule);
  }

  getRuleFileTypes(): string {
    if (this.rules.ext) {
      const useTranslated = this.$t('fields.file.use');
      return `${useTranslated} ${this.rules.ext.join(', ')}.`;
    }
    return '';
  }

  deleteFile(index: number, validate: () => any) {
    this.value.splice(index, 1);
    validate();
  }
}

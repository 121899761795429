




























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import { CompanyType, DeclarationApi, Mattress } from '@/api';
import AppAddDeclarationSummaryCompany from '@/components/declaration/AppAddDeclarationSummaryCompany.vue';
import getConfiguration from '@/store/getConfiguration';

@Component({
  components: { AppAddDeclarationSummaryCompany },
  props: {
    previous: {},
    submit: {},
  },
})
export default class AppAddDeclarationSummary extends Vue {
  @Prop({ type: String })
  type!: CompanyType;

  @Prop({ type: Object })
  declaration!: any; // Notification|Declaration;

  @Prop({ type: Array, default: () => [] })
  mattresses!: Mattress[];

  @Prop({ type: Function })
  getPeriodLabel!: (p: any) => string;

  @Prop({ type: Array, default: () => [] })
  attachments!: string[];

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop({ type: Boolean, default: false })
  isCorrection!: boolean;

  restitution? = '';

  restitutionLoading = false;

  created() {
    if (this.type === CompanyType.Creditor && !this.isCorrection) {
      this.fetchRestitution();
    }
  }

  get period(): string {
    return this.getPeriodLabel(this.declaration);
  }

  mattressLabel(mattressId: string): string {
    const mattress = this.mattresses.find((m) => m.id === mattressId);
    if (mattress) {
      return mattress.name;
    }
    return '-';
  }

  getValue(field: string): string {
    const numberValue =
      field === 'weight'
        ? this.declaration[field] / 1000
        : this.declaration[field];
    return `${numberValue}`.replace('.', ',');
  }

  getUnit(field: string): string {
    switch (field) {
      case 'amount':
      case 'transport_costs':
        return 'euro';
      case 'quantity':
        return 'quantity';
      case 'weight':
        return 'ton';
      default:
        return '';
    }
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new DeclarationApi(configuration, '');
  }

  async fetchRestitution() {
    const api = await this.getApi();
    try {
      this.restitutionLoading = true;
      const { data } = await api.getDeclarationRestitution(
        this.declaration.year,
        this.declaration.amount,
        this.declaration.transport_costs,
      );
      this.restitution = data.restitution;
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      this.restitutionLoading = false;
    }
  }
}

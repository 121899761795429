










































































import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import { Component, Vue } from 'vue-property-decorator';
import { UserApi } from '@/api';
import getConfiguration from '@/store/getConfiguration';

@Component({ components: { AppAuthenticationLayout } })
export default class AppAuthenticationSignup extends Vue {
  group = 'debtor';

  login: any = {
    email: '',
    password: '',
    accept: false,
  };

  errorMessages: any = {
    email: {
      type: '',
      message: '',
    },
    password: {
      type: '',
      message: '',
    },
  };

  isSaving = false;

  created() {
    this.group = this.$route.params.group;
  }

  async register() {
    const userObject = {
      email: this.login.email,
      group: this.group,
      password: this.login.password,
    };

    try {
      this.isSaving = true;
      const response = await this.createNewUser(userObject);
      const { data } = response;

      // Store username and password to login after verification step.
      this.$store.commit('auth/setSignInInformation', {
        email: data.email,
        password: userObject.password,
      });

      await this.$router.push(`/confirm-signup`).catch(() => {});
    } catch ({ response: { data: { error } = '' } = {} }) {
      this.manageErrors(error, 'authentication');
    } finally {
      this.isSaving = false;
    }
  }

  async createNewUser(userObject: any) {
    const configuration = await getConfiguration();
    const api = new UserApi(configuration, '');
    return api.postUser(userObject);
  }

  manageErrors(errorCode: any, prefix: any) {
    if (errorCode) {
      const error = `${prefix}.${errorCode.toLowerCase()}`;
      Object.keys(this.errorMessages).forEach((field) => {
        const emailExists =
          field === 'email' && errorCode === 'RESOURCE_ALREADY_EXISTS';
        if (error.includes(field) || emailExists) {
          this.errorMessages[field].type = 'is-danger';
          if (this.$te(error)) {
            this.errorMessages[field].message = this.$t(error) as string;
          } else {
            this.errorMessages[field].message = this.$t(
              `${prefix}.input_validation_error.${field}.unknown_error`,
            ) as string;
          }
        }
      });
    }
  }
}

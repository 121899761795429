










import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import * as DOMPurify from 'dompurify';
import AppDefaultLayout from '@/layouts/AppDefaultLayout.vue';

@Component({
  components: {
    AppDefaultLayout,
  },
})
export default class AppPromoOverview extends Vue {
  content = '';

  async fetchPromoData() {
    try {
      const response = await axios.get(
        'https://mrn.nl/wp-json/wp/v2/pages/2721',
      );

      this.content = DOMPurify.sanitize(response.data.content.rendered);

      return response.data;
    } catch (error) {
      return error;
    }
  }

  beforeMount() {
    this.fetchPromoData();
  }
}

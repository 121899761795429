
















































































































import { Component } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { friendlyFormatIBAN } from 'ibantools';
import { Company } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import BaseFieldSelect from '@/components/form/BaseFieldSelect.vue';
import AppProfileMixin from '@/mixins/AppProfileMixin';

@Component({
  components: { BaseFieldText, BaseFieldSelect, ValidationObserver },
})
export default class AppProfileStepCompany extends AppProfileMixin {
  stepErrors = 'company';

  countryList: { iso: string; country: string }[] = [];

  ibanFocussed = false;

  created() {
    if (
      this.company.address?.country === '' &&
      this.company.business_id_country
    ) {
      this.company.address.country = this.company.business_id_country;
    }

    this.countryList = this.getCountryList();
  }

  get company(): Company {
    return this.$store.state.company.company;
  }

  get friendlyIban() {
    return friendlyFormatIBAN(this.company.iban);
  }

  get hasUpdateProtection(): boolean {
    return this.$store.getters['company/hasId'];
  }

  kvkOrBusinessId(): string {
    return this.company.business_id_country === 'NL' ? 'kvk' : 'business_id';
  }

  getCountryList(): { iso: string; country: string }[] {
    const countryList = (this.$t('profile.countries') as unknown) as {
      [key: string]: string;
    };

    return Object.keys(countryList)
      .map((key) => ({ iso: key, country: countryList[key] }))
      .sort((a, b) => a.country.localeCompare(b.country));
  }

  setIban(iban: string) {
    this.$store.commit('company/setCompanyIban', iban);
  }

  nextStep() {
    this.$router.push({
      name: 'profile-step',
      params: { step: 'contact' },
    });
  }

  toggleIbanFocus(focus: boolean) {
    this.ibanFocussed = focus;
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDeclaration } from '../model';
// @ts-ignore
import { Declaration } from '../model';
// @ts-ignore
import { DeclarationRestitutionResponse } from '../model';
// @ts-ignore
import { Period } from '../model';
/**
 * DeclarationApi - axios parameter creator
 * @export
 */
export const DeclarationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves declarations.
         * @param {string} [companyId] ID of the company to fetch the declarations for. Only allowed for admin users. For normal users no company_id param should be specified, as the declarations for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationOverview: async (companyId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/declaration/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the available periods to make a declaration for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationPeriods: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/declaration/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculates the restituted amount for the given costs in a given year.
         * @summary Calculate restitution
         * @param {number} [year] The year of the declaration period
         * @param {string} [amount] Amount in euro\&#39;s. Precision and scale are separated by a dot. Valid values are: \&#39;100.00\&#39;, \&#39;100.0\&#39;, \&#39;100\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationRestitution: async (year?: number, amount?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/declaration/restitution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new declaration
         * @summary Create declaration
         * @param {CreateDeclaration} [createDeclaration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeclaration: async (createDeclaration?: CreateDeclaration, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/declaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeclaration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationApi - functional programming interface
 * @export
 */
export const DeclarationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeclarationApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves declarations.
         * @param {string} [companyId] ID of the company to fetch the declarations for. Only allowed for admin users. For normal users no company_id param should be specified, as the declarations for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeclarationOverview(companyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Declaration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeclarationOverview(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the available periods to make a declaration for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeclarationPeriods(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Period>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeclarationPeriods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calculates the restituted amount for the given costs in a given year.
         * @summary Calculate restitution
         * @param {number} [year] The year of the declaration period
         * @param {string} [amount] Amount in euro\&#39;s. Precision and scale are separated by a dot. Valid values are: \&#39;100.00\&#39;, \&#39;100.0\&#39;, \&#39;100\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeclarationRestitution(year?: number, amount?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeclarationRestitutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeclarationRestitution(year, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new declaration
         * @summary Create declaration
         * @param {CreateDeclaration} [createDeclaration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeclaration(createDeclaration?: CreateDeclaration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Declaration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeclaration(createDeclaration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeclarationApi - factory interface
 * @export
 */
export const DeclarationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeclarationApiFp(configuration)
    return {
        /**
         * Retrieves declarations.
         * @param {string} [companyId] ID of the company to fetch the declarations for. Only allowed for admin users. For normal users no company_id param should be specified, as the declarations for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationOverview(companyId?: string, options?: any): AxiosPromise<Array<Declaration>> {
            return localVarFp.getDeclarationOverview(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the available periods to make a declaration for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationPeriods(options?: any): AxiosPromise<Array<Period>> {
            return localVarFp.getDeclarationPeriods(options).then((request) => request(axios, basePath));
        },
        /**
         * Calculates the restituted amount for the given costs in a given year.
         * @summary Calculate restitution
         * @param {number} [year] The year of the declaration period
         * @param {string} [amount] Amount in euro\&#39;s. Precision and scale are separated by a dot. Valid values are: \&#39;100.00\&#39;, \&#39;100.0\&#39;, \&#39;100\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationRestitution(year?: number, amount?: string, options?: any): AxiosPromise<DeclarationRestitutionResponse> {
            return localVarFp.getDeclarationRestitution(year, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new declaration
         * @summary Create declaration
         * @param {CreateDeclaration} [createDeclaration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeclaration(createDeclaration?: CreateDeclaration, options?: any): AxiosPromise<Declaration> {
            return localVarFp.postDeclaration(createDeclaration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeclarationApi - object-oriented interface
 * @export
 * @class DeclarationApi
 * @extends {BaseAPI}
 */
export class DeclarationApi extends BaseAPI {
    /**
     * Retrieves declarations.
     * @param {string} [companyId] ID of the company to fetch the declarations for. Only allowed for admin users. For normal users no company_id param should be specified, as the declarations for the company of the authenticated user will be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public getDeclarationOverview(companyId?: string, options?: any) {
        return DeclarationApiFp(this.configuration).getDeclarationOverview(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the available periods to make a declaration for
     * @summary Get periods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public getDeclarationPeriods(options?: any) {
        return DeclarationApiFp(this.configuration).getDeclarationPeriods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculates the restituted amount for the given costs in a given year.
     * @summary Calculate restitution
     * @param {number} [year] The year of the declaration period
     * @param {string} [amount] Amount in euro\&#39;s. Precision and scale are separated by a dot. Valid values are: \&#39;100.00\&#39;, \&#39;100.0\&#39;, \&#39;100\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public getDeclarationRestitution(year?: number, amount?: string, options?: any) {
        return DeclarationApiFp(this.configuration).getDeclarationRestitution(year, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new declaration
     * @summary Create declaration
     * @param {CreateDeclaration} [createDeclaration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public postDeclaration(createDeclaration?: CreateDeclaration, options?: any) {
        return DeclarationApiFp(this.configuration).postDeclaration(createDeclaration, options).then((request) => request(this.axios, this.basePath));
    }
}

































import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

@Component({
  components: { AppAuthenticationLayout },
  computed: { ...mapState('auth', ['signInInformation']) },
})
export default class AppAuthenticationConfirmSignup extends Vue {
  public signInInformation: any;

  verifyCode = '';

  verifyErrorMessage = {
    type: '',
    message: '',
  };

  isSaving = false;

  get validVerifyCodeLength(): boolean {
    return this.verifyCode.length === 6;
  }

  async verify() {
    const user = {
      email: this.signInInformation.email,
      code: this.verifyCode,
    };
    try {
      this.isSaving = true;
      await this.$store.dispatch('auth/confirmSignUp', user);
      this.$buefy.toast.open({
        message: this.$t('authentication.verify.success') as string,
        type: 'is-success',
        duration: 7000,
      });
      await this.$router.push({ name: 'Dashboard' }).catch(() => {});
    } catch (e) {
      Sentry.captureException(e);
      this.manageErrors(e.name);
    } finally {
      this.isSaving = false;
    }
  }

  manageErrors(errorCode: string) {
    if (this.$te(`authentication.verify.form.error.${errorCode}`)) {
      this.verifyErrorMessage.message = this.$t(
        `authentication.verify.form.error.${errorCode}`,
      ) as string;
      this.verifyErrorMessage.type = 'is-danger';
    }
  }
}

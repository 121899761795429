




















































































import AppAuthenticationLayout from '@/layouts/AppAuthenticationLayout.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { paramCase } from 'param-case';

import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { Auth } from 'aws-amplify';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import * as Sentry from '@sentry/vue';

interface FormError {
  type?: string;
  message?: string;
}

@Component({
  components: { AppAuthenticationLayout, BaseFieldText, ValidationObserver },
  computed: { ...mapState('auth', ['challengedUser']) },
})
export default class AppAuthenticationChallenge extends Vue {
  loader = false;

  // Form model
  value = '';

  challengedUser!: CognitoUserInterface;

  error: FormError = {};

  get challenge(): string | null {
    return this.challengedUser && this.challengedUser.challengeName
      ? paramCase(this.challengedUser.challengeName)
      : null;
  }

  async confirmNewPassword() {
    this.loader = true;
    await Auth.completeNewPassword(this.challengedUser, this.value);
    this.loader = false;
  }

  async submitVerificationCode() {
    this.loader = true;
    try {
      await Auth.sendCustomChallengeAnswer(this.challengedUser, this.value);
      await Auth.currentSession();
      await this.$router.push({ name: 'Dashboard' }).catch(() => {});
    } catch (e) {
      Sentry.captureException(e);
      this.handleError(e);
    } finally {
      this.loader = false;
    }
  }

  handleError(e: any) {
    let error: string;
    switch (e.code) {
      case 'NotAuthorizedException':
        switch (e.message) {
          case 'Incorrect username or password.':
            // 3x wrong code entered
            error = 'not-auth-too-many-tries';
            break;
          case 'Invalid session for the user.':
            // Session timeout
            error = 'not-auth-invalid-session';
            break;
          default:
            error = 'not-auth-unknown';
            break;
        }

        break;
      default:
        error = 'wrong-code';
        break;
    }

    const transKey = `authentication.challenge.custom-challenge.error.${error}`;
    const translation = this.$t(transKey) as string;
    if (error === 'wrong-code') {
      this.error = {
        type: 'is-danger',
        message: translation,
      };
    } else {
      // Show error message in toast or otherwise?
      this.$buefy.toast.open({
        message: translation,
        type: 'is-danger',
        duration: 7000,
      });
      this.$router.push({ name: 'Dashboard' });
    }
  }

  @Watch('value', { immediate: true })
  resetError() {
    this.error = {};
  }
}

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import Buefy from 'buefy';

import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from './i18n';
import './vee-validate';

import '@/assets/scss/app.scss';

// TechnicalDebt: Resolve issue with loading the awsExport
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import awsExports from './awsExports';

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
});

Amplify.configure(awsExports);
AmplifyModules.Auth.configure(awsExports);
Vue.use(AmplifyPlugin, AmplifyModules);

axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH;
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

require('./icons/icon.font');

Vue.use(Buefy, {
  customIconPacks: {
    mrn: {
      sizes: {
        default: 'is-medium',
        'is-small': 'is-size-5',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1',
      },
      iconPrefix: 'mrn-',
    },
  },
  defaultIconPack: 'mrn',
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

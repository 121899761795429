/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateUser } from '../model';
// @ts-ignore
import { InlineObject } from '../model';
// @ts-ignore
import { InviteUser } from '../model';
// @ts-ignore
import { UpdateEmail } from '../model';
// @ts-ignore
import { User } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Invites a new user. For now only admins may invite other admins.
         * @summary Invite user
         * @param {InviteUser} [inviteUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: async (inviteUser?: InviteUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user
         * @summary Create user
         * @param {CreateUser} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (createUser?: CreateUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the email address of the authenticated user.
         * @summary Update the email address of the authenticated user.
         * @param {string} [id] ID of the user to update the email address. Only allowed for admin users. For normal users no id param should be specified, as the authenticated user is used.
         * @param {UpdateEmail} [updateEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmail: async (id?: string, updateEmail?: UpdateEmail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transfer an account by updating the email address and resetting the password.
         * @summary Transfer a user by updating the email address and resetting the password. Only allowed for admin users.
         * @param {string} [id] ID of the user to transfer.
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTransfer: async (id?: string, inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reads the user\'s email address from Cognito and updates it in the MRN database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Invites a new user. For now only admins may invite other admins.
         * @summary Invite user
         * @param {InviteUser} [inviteUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUser(inviteUser?: InviteUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUser(inviteUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user
         * @summary Create user
         * @param {CreateUser} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(createUser?: CreateUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(createUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the email address of the authenticated user.
         * @summary Update the email address of the authenticated user.
         * @param {string} [id] ID of the user to update the email address. Only allowed for admin users. For normal users no id param should be specified, as the authenticated user is used.
         * @param {UpdateEmail} [updateEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEmail(id?: string, updateEmail?: UpdateEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEmail(id, updateEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Transfer an account by updating the email address and resetting the password.
         * @summary Transfer a user by updating the email address and resetting the password. Only allowed for admin users.
         * @param {string} [id] ID of the user to transfer.
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTransfer(id?: string, inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTransfer(id, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reads the user\'s email address from Cognito and updates it in the MRN database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Invites a new user. For now only admins may invite other admins.
         * @summary Invite user
         * @param {InviteUser} [inviteUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(inviteUser?: InviteUser, options?: any): AxiosPromise<User> {
            return localVarFp.inviteUser(inviteUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user
         * @summary Create user
         * @param {CreateUser} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(createUser?: CreateUser, options?: any): AxiosPromise<User> {
            return localVarFp.postUser(createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the email address of the authenticated user.
         * @summary Update the email address of the authenticated user.
         * @param {string} [id] ID of the user to update the email address. Only allowed for admin users. For normal users no id param should be specified, as the authenticated user is used.
         * @param {UpdateEmail} [updateEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmail(id?: string, updateEmail?: UpdateEmail, options?: any): AxiosPromise<User> {
            return localVarFp.putEmail(id, updateEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Transfer an account by updating the email address and resetting the password.
         * @summary Transfer a user by updating the email address and resetting the password. Only allowed for admin users.
         * @param {string} [id] ID of the user to transfer.
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTransfer(id?: string, inlineObject?: InlineObject, options?: any): AxiosPromise<User> {
            return localVarFp.putTransfer(id, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reads the user\'s email address from Cognito and updates it in the MRN database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(options?: any): AxiosPromise<void> {
            return localVarFp.verifyEmail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Invites a new user. For now only admins may invite other admins.
     * @summary Invite user
     * @param {InviteUser} [inviteUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public inviteUser(inviteUser?: InviteUser, options?: any) {
        return UserApiFp(this.configuration).inviteUser(inviteUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user
     * @summary Create user
     * @param {CreateUser} [createUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUser(createUser?: CreateUser, options?: any) {
        return UserApiFp(this.configuration).postUser(createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the email address of the authenticated user.
     * @summary Update the email address of the authenticated user.
     * @param {string} [id] ID of the user to update the email address. Only allowed for admin users. For normal users no id param should be specified, as the authenticated user is used.
     * @param {UpdateEmail} [updateEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putEmail(id?: string, updateEmail?: UpdateEmail, options?: any) {
        return UserApiFp(this.configuration).putEmail(id, updateEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transfer an account by updating the email address and resetting the password.
     * @summary Transfer a user by updating the email address and resetting the password. Only allowed for admin users.
     * @param {string} [id] ID of the user to transfer.
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putTransfer(id?: string, inlineObject?: InlineObject, options?: any) {
        return UserApiFp(this.configuration).putTransfer(id, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reads the user\'s email address from Cognito and updates it in the MRN database.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public verifyEmail(options?: any) {
        return UserApiFp(this.configuration).verifyEmail(options).then((request) => request(this.axios, this.basePath));
    }
}

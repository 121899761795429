





















import TheLogoPanel from '@/layouts/TheLogoPanel.vue';

export default {
  components: { TheLogoPanel },
};

/* tslint:disable */
/* eslint-disable */
/**
 * MNR API
 * CBM MNR Portal API documentation
 *
 * The version of the OpenAPI document: 1.4.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateNotification } from '../model';
// @ts-ignore
import { Mattress } from '../model';
// @ts-ignore
import { Notification } from '../model';
// @ts-ignore
import { Period } from '../model';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the available mattresses that can be used to create a notification.
         * @summary Get mattresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMattresses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/mattresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves notifications.
         * @param {string} [companyId] ID of the company to fetch the notifications for. Only allowed for admin users. For normal users no company_id param should be specified, as the notifications for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationOverview: async (companyId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the available periods to make a notification for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationPeriods: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new notification
         * @summary Create notification
         * @param {CreateNotification} [createNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification: async (createNotification?: CreateNotification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-60iocaong94h1jqn8500bje6sh required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-60iocaong94h1jqn8500bje6sh", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the available mattresses that can be used to create a notification.
         * @summary Get mattresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMattresses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mattress>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMattresses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves notifications.
         * @param {string} [companyId] ID of the company to fetch the notifications for. Only allowed for admin users. For normal users no company_id param should be specified, as the notifications for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationOverview(companyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationOverview(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the available periods to make a notification for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationPeriods(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Period>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationPeriods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new notification
         * @summary Create notification
         * @param {CreateNotification} [createNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotification(createNotification?: CreateNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotification(createNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * Gets the available mattresses that can be used to create a notification.
         * @summary Get mattresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMattresses(options?: any): AxiosPromise<Array<Mattress>> {
            return localVarFp.getMattresses(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves notifications.
         * @param {string} [companyId] ID of the company to fetch the notifications for. Only allowed for admin users. For normal users no company_id param should be specified, as the notifications for the company of the authenticated user will be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationOverview(companyId?: string, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotificationOverview(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the available periods to make a notification for
         * @summary Get periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationPeriods(options?: any): AxiosPromise<Array<Period>> {
            return localVarFp.getNotificationPeriods(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new notification
         * @summary Create notification
         * @param {CreateNotification} [createNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification(createNotification?: CreateNotification, options?: any): AxiosPromise<Notification> {
            return localVarFp.postNotification(createNotification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * Gets the available mattresses that can be used to create a notification.
     * @summary Get mattresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getMattresses(options?: any) {
        return NotificationApiFp(this.configuration).getMattresses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves notifications.
     * @param {string} [companyId] ID of the company to fetch the notifications for. Only allowed for admin users. For normal users no company_id param should be specified, as the notifications for the company of the authenticated user will be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationOverview(companyId?: string, options?: any) {
        return NotificationApiFp(this.configuration).getNotificationOverview(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the available periods to make a notification for
     * @summary Get periods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationPeriods(options?: any) {
        return NotificationApiFp(this.configuration).getNotificationPeriods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new notification
     * @summary Create notification
     * @param {CreateNotification} [createNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public postNotification(createNotification?: CreateNotification, options?: any) {
        return NotificationApiFp(this.configuration).postNotification(createNotification, options).then((request) => request(this.axios, this.basePath));
    }
}



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import getConfiguration from '@/store/getConfiguration';
import { CompanyOverview, UserApi } from '@/api';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/vue';

interface FormError {
  type?: string;
  message?: string;
}

@Component({
  components: {
    BaseFieldText,
    ValidationObserver,
  },
})
export default class AppAdminEmailForm extends Vue {
  // Change own email or email of a company
  @Prop({ type: String, default: 'other' })
  mode!: 'self' | 'other';

  // Give the company when changing the e-mail for another company
  @Prop({ type: Object, default: () => ({}) })
  company!: CompanyOverview;

  email = '';

  code = '';

  requireVerify = false;

  error: FormError = {};

  isSaving = false;

  created() {
    if (this.mode === 'other') {
      this.email =
        this.company.user && this.company.user.email
          ? this.company.user.email
          : '';
    } else {
      this.email = this.$store.getters['auth/email'];
    }
  }

  @Watch('email', { immediate: true })
  resetError() {
    this.error = {};
  }

  async getApi() {
    const configuration = await getConfiguration();
    return new UserApi(configuration, '');
  }

  async save() {
    if (this.requireVerify) {
      await this.verifyCode();
      return;
    }

    try {
      if (this.mode === 'other' && !this.company.user) {
        throw new Error('Company has no user');
      }
      this.isSaving = true;
      const api = await this.getApi();
      const id = this.mode === 'other' ? this.company?.user?.id : undefined;
      await api.putEmail(id, { email: this.email });
      if (this.mode === 'other' && this.company.user) {
        this.company.user.email = this.email;
        this.$emit('close');
      }

      if (this.mode === 'self') {
        this.requireVerify = true;
      }
    } catch (e) {
      Sentry.captureException(e);
      this.error = {
        type: 'is-danger',
        message: this.$t('admin.email-form.error') as string,
      };
    } finally {
      this.isSaving = false;
    }
  }

  async verifyCode() {
    try {
      this.isSaving = true;
      await Auth.verifyCurrentUserAttributeSubmit('email', this.code);
      await this.$store.dispatch('auth/getUser', true);
      this.$emit('close');
      this.validateEmail();
    } catch (e) {
      Sentry.captureException(e);
      this.error = {
        type: 'is-danger',
        message: this.$t(`admin.email-form.${e.name}`) as string,
      };
    } finally {
      this.isSaving = false;
    }
  }

  async validateEmail() {
    try {
      await this.$store.dispatch('auth/validateEmail');
    } catch (e) {
      this.$buefy.dialog.alert({
        type: 'is-dark',
        title: this.$t('admin.email-form.verify-email-error.title') as string,
        message: this.$t('admin.email-form.verify-email-error.text') as string,
        confirmText: this.$t(
          'admin.email-form.verify-email-error.confirm',
        ) as string,
      });
      Sentry.captureException(e);
    }
  }
}
